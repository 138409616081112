import React, { ChangeEvent, useState } from "react";
import { InputComponentProps } from "../../types";

export const JSONField = ({
  field,
  helpers,
  placeholder,
}: InputComponentProps<any>) => {
  const [jsonValue, setJsonValue] = useState<string>(
    JSON.stringify(field.value || {}, null, 2)
  );
  const [error, setError] = useState<Error | null>(null);

  const handleUpdate = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setJsonValue(value);
    try {
      const data = JSON.parse(value);
      helpers.setValue(data);
      setError(null);
    } catch (err) {
      setError(err as Error);
    }
  };

  return (
    <div>
      <textarea
        {...field}
        value={jsonValue}
        onChange={handleUpdate}
        placeholder={placeholder as string}
      />
      {error && (
        <div className="error">
          {error.toString()}
          {error.stack}
        </div>
      )}
    </div>
  );
};

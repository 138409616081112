import { useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { deepEquals } from "../../utils/deepEquals";

export function ValuesUpdater<TValue>({
  onChange = (values: any) => {},
  debounceTime = 250,
  shouldValidate = false,
}) {
  const { values, validateForm } = useFormikContext<TValue>();
  const runOnce = useRef(false);
  const prevValues = useRef(values);
  const onChangeRef = useRef(onChange);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (!runOnce.current) {
      runOnce.current = true;
      return;
    }

    timeoutRef.current = setTimeout(() => {
      if (!deepEquals(values, prevValues.current)) {
        onChangeRef.current?.(values);
        prevValues.current = values;
        if (shouldValidate) {
          // validateForm();
        }
      }
    }, debounceTime);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [values, shouldValidate]);

  return null;
}

import { FormFieldDto } from "../../../dtos";

export const getShouldAutoFocus = (config: FormFieldDto, value: any) => {
  const { isAutoFocus, conditions = [] } = config;
  if (!isAutoFocus) {
    return false;
  }
  if (conditions.length === 0 && isAutoFocus && !value) {
    return true;
  }
  if (conditions.length > 1 && isAutoFocus && !value) {
    return true;
  }
};

export const getLabelSize = (size: string) => {
  return size === "small" ? "small" : "normal";
};

export function getUniqueArray<T = any>(arr: T[], valueKey = "id") {
  const seen: { [key: string]: boolean } = {};
  return arr.reduce((filteredArr: any[], item: any) => {
    if (!item) {
      return filteredArr;
    }
    if (
      typeof item === "object" &&
      item?.[valueKey] &&
      !seen[item?.[valueKey]]
    ) {
      filteredArr.push(item);
      seen[item[valueKey]] = true;
      return filteredArr;
    } else if (
      (typeof item === "string" || typeof item === "number") &&
      !seen[item]
    ) {
      filteredArr.push(item);
      seen[item] = true;
      return filteredArr;
    }
    return filteredArr;
  }, []) as T[];
}

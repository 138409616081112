import { useMemo } from "react";
import { InputComponentProps, ColumnsProp } from "../../types";
import "./ObjectField.scss";
import { Box, Grid, Typography } from "@mui/material";
import { WrappedSchemaFormField } from "../../../FormSchemaForm/WrappedFormField";
import { filterField } from "../../SchemaForm/formUtils";

interface Props {
  columns?: any;
  isRecursive?: boolean;
  isChild?: boolean;
  isStringValues?: boolean;
  showRecursiveChildren?: (values?: any) => boolean;
}

export function ObjectField({
  field,
  helpers,
  columns = [],
  isObjectFormat,
  isInlineForm,
  label,
  config,
}: InputComponentProps<any> & Props) {
  const { value, name } = field;
  const { isObjectField, isFieldsOnly } = config;

  const mappedFields = useMemo(() => {
    if (!config.fields?.length) {
      return [];
    }
    return config.fields.filter(filterField(value)).map((subField) => {
      return config.isObjectField
        ? { ...subField, name: `${field.name}.${subField.name}` }
        : subField;
    });
  }, [config.fields, value]);

  const fieldLabel = label || config.label || field.name;
  const isLabelHidden = !!config?.data?.isLabelHidden;

  // this is an array item and we should only render the fields
  if (isFieldsOnly) {
    <Grid container spacing={2}>
      {mappedFields.map((field) => {
        return <WrappedSchemaFormField key={field.id} fieldConfig={field} />;
      })}
    </Grid>;
  }

  return (
    <Grid item xs={12}>
      {fieldLabel && !isLabelHidden && (
        <Box mb={2}>
          <Typography component="b" fontWeight={600} fontSize="1rem">
            {fieldLabel}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2}>
        {mappedFields.map((field) => {
          return <WrappedSchemaFormField key={field.id} fieldConfig={field} />;
        })}
      </Grid>
    </Grid>
  );
}

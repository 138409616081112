import { ChangeEvent, useState, FC } from "react";
import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { API } from "../../client/API";
import { UploadsDto } from "../../dtos";

const getFormData = (files: FileList): FormData => {
  const formData = new FormData();

  Array.from(files).forEach((file, idx) => {
    formData.append("images", file as any, file.name);
  });

  return formData;
};

type Props = {
  onUploadComplete?: (uploads: UploadsDto[]) => void;
  initialValues?: UploadsDto[];
};

export const UploadButton: FC<ButtonProps & Props> = ({
  children,
  className,
  variant = "contained",
  color = "inherit",
  onUploadComplete,
  size = "large",
  sx = {},
}) => {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [uploadCount, setUploadCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);

  const renderBtnLabel = () => {
    if (isLoading) {
      return `Uploading ${uploadCount} files...`;
    }
    return children || "Upload";
  };

  const renderStartIcon = () => {
    if (isLoading) {
      return <CircularProgress size={18} color="inherit" />;
    }
    return <AddAPhotoIcon />;
  };

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (!files?.length) return;
    const formData = getFormData(files);

    setIsLoading(true);
    setUploadCount(files.length);

    API.upload(formData)
      .then((data) => {
        setIsLoading(false);
        setUploadCount(0);
        setError(null);
        onUploadComplete?.(data);
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
        setUploadCount(0);
      });
  };

  return (
    <Box>
      <Button
        startIcon={renderStartIcon()}
        variant={variant}
        disabled={isLoading}
        size={size}
        component="label"
        className={className}
        color={color}
        sx={{
          fontWeight: "normal",
          cursor: "pointer",
          position: "relative",
          ...sx,
        }}
      >
        <Box
          component="input"
          sx={{
            opacity: 0,
            position: "absolute",
            width: 1,
            height: 1,
            overflow: "hidden",
            cursor: "pointer",
          }}
          multiple={true}
          name="images"
          onChange={onChange}
          type="file"
        />
        <Box position="relative" zIndex={1} sx={{ cursor: "pointer" }}>
          {renderBtnLabel()}
        </Box>
      </Button>
      {error && <Box>{error.toString()}</Box>}
    </Box>
  );
};

import Close from "@mui/icons-material/Close";
import { IconButton, Paper } from "@mui/material";
import classNames from "clsx";
import React, {
  PropsWithChildren,
  type ReactNode,
  type TransitionEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { NOOP } from "../../utils/NOOP";
import { useDialogContext } from "../Providers/DialogProvider";
import { useIsClient } from "../../hooks/useIsClient";
import "./DialogDrawer.scss";

type Props = {
  isOpen: boolean;
  className?: string;
  hideBackBtn?: boolean;
  title?: string;
  onClose?: () => void;
  content?: ReactNode;
  footer?: ReactNode;
  maxWidth?: number;
  containerElement?: HTMLElement;
  showBackdrop?: boolean;
  autoFocus?: boolean;
};

const DialogDrawer: React.FC<PropsWithChildren<Props>> = ({
  autoFocus = true,
  isOpen,
  onClose = NOOP,
  title,
  hideBackBtn,
  className,
  children,
  footer,
  maxWidth = 300,
  showBackdrop = false,
  containerElement,
}) => {
  const isClient = useIsClient();
  const overlayElementRef = useRef<HTMLDivElement>(null);
  const [isPanelOpen, setIsPanelOpen] = useState<boolean>(isOpen);

  const { mainElement } = useDialogContext();

  const containerEl = useMemo<HTMLElement | null>(() => {
    if (!isClient) {
      return null;
    }
    return containerElement || mainElement || document.body;
  }, [mainElement]);

  useEffect(() => {
    if (!isOpen) {
      return () => undefined;
    }
    setIsPanelOpen(isOpen);

    const handleClose = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        // stop modal from closing
        e.stopPropagation();
        onClose();
      }
    };

    document.body.addEventListener("keydown", handleClose, false);

    return () => {
      document.body.removeEventListener("keydown", handleClose, false);
    };
  }, [isOpen]);

  // when passed isOpen prop changes to false, run animation before unmounting
  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    const { target, currentTarget } = e;
    if (target === currentTarget) {
      setIsPanelOpen(isOpen);
    }
  };

  const shouldRenderPortal = (isPanelOpen || isOpen) && containerEl;
  const isEntering = isOpen && !isPanelOpen;
  const isLeaving = isPanelOpen && !isOpen;

  useEffect(() => {
    if (!overlayElementRef.current || !autoFocus) {
      return;
    }
    const anyInput = overlayElementRef.current.querySelector("input");

    anyInput?.focus();
  }, [overlayElementRef.current, autoFocus]);

  if (!shouldRenderPortal) {
    return null;
  }

  return (
    <>
      {createPortal(
        <>
          <Paper
            className={classNames("sidebar-overlay-editor", className, {
              "sidebar-is-entering": isEntering,
              "sidebar-is-leaving": isLeaving,
            })}
            data-drawer="true"
            style={{ maxWidth }}
            onClick={(e) => e.stopPropagation()}
            ref={overlayElementRef}
            onTransitionEnd={handleTransitionEnd}
            elevation={6}
          >
            <div className="sidebar-overlay-editor-wrapper">
              {!!title && (
                <div
                  className={classNames(
                    "sidebar-overlay-editor-header",
                    className
                  )}
                >
                  <h3 className="sidebar-overlay-title">{title}</h3>
                  {!hideBackBtn && (
                    <IconButton className="close-btn" onClick={onClose}>
                      <Close />
                    </IconButton>
                  )}
                </div>
              )}

              <div className="sidebar-overlay-editor-main existing-metrics-list">
                {children}
              </div>
              {!!footer && (
                <div className="sidebar-overlay-editor-footer">{footer}</div>
              )}
            </div>
          </Paper>
          {showBackdrop && (
            <div
              onClick={onClose}
              className={classNames("sidebar-overlay-backdrop", {
                "sidebar-is-entering": isEntering,
                "sidebar-is-leaving": isLeaving,
              })}
            />
          )}
        </>,
        containerEl
      )}
    </>
  );
};

export default DialogDrawer;

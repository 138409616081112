import {
  Box,
  OutlinedInput,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useToggle from "../../../../hooks/useToggle";
import styles from "./PasswordField.module.scss";
import { getLabelSize } from "../../fieldUtils/fieldUtils";

type PasswordFieldProps = {
  field: {
    name: string;
    value: string;
  };
  placeholder?: string;
  autoFocus?: boolean;
  helpers: any;
  inputAriaProps?: any;
  labelProps?: any;
  label?: string;
  config?: any;
  meta: any;
  size?: any;
};

export const PasswordField = forwardRef<HTMLInputElement, PasswordFieldProps>(
  function DefaultComponent(
    {
      field,
      placeholder,
      autoFocus,
      helpers,
      inputAriaProps = {} as any,
      labelProps,
      label,
      config = {} as any,
      meta,
      size,
    },
    ref: ForwardedRef<HTMLInputElement>
  ) {
    const [password, setPassword] = useState<string>(field.value || "");
    const [retypedPassword, setRetypedPassword] = useState<string>("");
    const { data = {} } = config as any;
    const isRetypeRequired = data.isRetypeRequired?.toString() === "true";
    const [showPassword, toggleShowPassword] = useToggle(false);
    const [showRetypedPassword, toggleShowRetypedPassword] = useToggle(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    };

    const handleRetypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setRetypedPassword(e.target.value);
    };

    useEffect(() => {
      if (!isRetypeRequired) {
        return;
      }
      if (
        password.length > 0 &&
        retypedPassword.length > 0 &&
        password === retypedPassword
      ) {
        helpers.setValue(password);
        helpers.setError(undefined);
      } else {
        helpers.setError("Passwords do not match.");
      }
    }, [password, retypedPassword, isRetypeRequired]);

    if (isRetypeRequired) {
      return (
        <Box display="flex" flexDirection="column" gap={2}>
          <FormControl
            fullWidth
            className={styles.passwordField}
            variant="outlined"
          >
            <InputLabel size={getLabelSize(size)} {...labelProps}>
              {label || field.name}
            </InputLabel>
            <OutlinedInput
              autoFocus={autoFocus}
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handleChange}
              inputProps={{
                ...inputAriaProps,
                min: data.minValue,
                max: data.maxValue,
              }}
              size={size}
              label={label || field.name}
              fullWidth
              className={`default-field field-password`}
              placeholder={placeholder as string}
              endAdornment={
                <IconButton
                  tabIndex={-1}
                  onClick={toggleShowPassword}
                  size="small"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              }
            />
          </FormControl>
          <FormControl
            fullWidth
            className={styles.passwordField}
            variant="outlined"
          >
            <InputLabel size={getLabelSize(size)} id="repeat-password">
              Repeat password
            </InputLabel>
            <OutlinedInput
              autoFocus={autoFocus}
              type={showRetypedPassword ? "text" : "password"}
              onChange={handleRetypeChange}
              value={retypedPassword}
              inputProps={{
                "aria-describedby": "repeat-password",
                min: data.minValue,
                max: data.maxValue,
              }}
              label="Reenter password"
              fullWidth
              className={`default-field field-password`}
              placeholder={placeholder as string}
              size={size}
              endAdornment={
                <IconButton
                  tabIndex={-1}
                  onClick={toggleShowRetypedPassword}
                  size="small"
                >
                  {showRetypedPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              }
            />
          </FormControl>
          {meta.hasError && (
            <Box mt={-1.5} color="error.main" fontSize="small">
              {meta.error}
            </Box>
          )}
        </Box>
      );
    }

    return (
      <FormControl
        fullWidth
        className={styles.passwordField}
        variant="outlined"
      >
        <InputLabel size={getLabelSize(size)} {...labelProps}>
          {label || field.name}
        </InputLabel>
        <OutlinedInput
          {...field}
          autoFocus={autoFocus}
          type="password"
          value={field.value || ""}
          inputProps={{
            ...inputAriaProps,
            min: data.minValue,
            max: data.maxValue,
          }}
          label={config.label || field.name}
          fullWidth
          className={`default-field field-password`}
          placeholder={placeholder as string}
          size={size}
          endAdornment={
            <IconButton tabIndex={-1} onClick={toggleShowPassword} size="small">
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          }
        />
      </FormControl>
    );
  }
);

import { MutableRefObject, useEffect, useRef, useState } from "react";

const getInputName = (el: HTMLElement) => {
  return el.getAttribute("name") || el.getAttribute("type");
};

export const useSheetKeyboardEvents = (
  sheetRef: MutableRefObject<HTMLDivElement>,
  addItem: () => void,
  ...deps: any[]
) => {
  const [sheetElement, setSheetElement] = useState<HTMLDivElement>();
  const currentFocusedIdx = useRef<number>();

  useEffect(() => {
    setSheetElement(sheetRef.current);
  }, [sheetRef]);

  useEffect(() => {
    if (!sheetElement) {
      return;
    }
    const allInputs = Array.from(
      sheetElement.querySelectorAll(
        'button,input[type="text"],input[type="checkbox"]'
      )
    );

    allInputs.forEach((el, idx) => el.setAttribute("data-idx", `${idx}`));

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!allInputs.length) {
        return;
      }
      currentFocusedIdx.current = allInputs.findIndex(
        (el) => el === document.activeElement
      );

      if (currentFocusedIdx.current === -1) {
        return;
      }

      if (e.key === "ArrowLeft") {
        return;
      }
      if (e.key === "ArrowRight") {
        return;
      }
      const currentEl = allInputs[currentFocusedIdx.current] as HTMLElement;
      const inputName = getInputName(currentEl);
      if (e.key === "ArrowUp") {
        if (currentFocusedIdx.current === 0) {
          return;
        }
        if (inputName) {
          const slicedInputs = allInputs
            .slice(0, currentFocusedIdx.current)
            .reverse();
          const prevInput = slicedInputs.find(
            (el): el is HTMLElement =>
              el instanceof HTMLElement && getInputName(el) === inputName
          ) as HTMLInputElement;
          prevInput?.focus();
        }
      }

      if (e.key === "ArrowDown") {
        if (currentFocusedIdx.current > allInputs.length - 1) {
          addItem();
          return;
        }
        if (inputName) {
          const slicedInputs = allInputs.slice(
            currentFocusedIdx.current + 1,
            allInputs.length
          );
          const nextInput = slicedInputs.find(
            (el): el is HTMLElement =>
              el instanceof HTMLElement && getInputName(el) === inputName
          ) as HTMLInputElement;
          if (nextInput) {
            nextInput?.focus();
          } else {
            addItem();
          }
        }
      }

      if (
        e.key === "Tab" &&
        document.activeElement === allInputs[allInputs.length - 1]
      ) {
        addItem();
      }
    };

    sheetElement.addEventListener("keydown", handleKeyDown, false);

    return () => {
      sheetElement.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [sheetElement, ...deps]);
};

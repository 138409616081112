const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

const KILOBYTE = 1024;

export function formatBytes(bytes: number, decimals: number = 1): string {
  if (bytes == 0) return "0 Bytes";
  const sizeIdx = Math.floor(Math.log(bytes) / Math.log(KILOBYTE));

  return [
    parseFloat((bytes / Math.pow(KILOBYTE, sizeIdx)).toFixed(decimals)),
    sizes[sizeIdx],
  ].join(" ");
}

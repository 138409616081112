import React from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Box, IconButton } from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

interface SortableItemProps<T> {
  id: string;
  item: T;
  renderItem: (item: T) => React.ReactNode;
}

function SortableItem<T>({ id, item, renderItem }: SortableItemProps<T>) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      sx={{ width: "100%", overflow: "hidden" }}
      ref={setNodeRef}
      boxShadow={1}
      style={style}
      {...attributes}
    >
      <Box>
        <IconButton
          disableRipple
          sx={{ cursor: "move", padding: 0 }}
          {...listeners}
        >
          <DragIndicatorIcon />
        </IconButton>
      </Box>
      <Box width="100%" flex={1}>
        {renderItem(item)}
      </Box>
    </Box>
  );
}

interface DraggableListProps<T> {
  items: T[];
  setItems: React.Dispatch<React.SetStateAction<T[]>>;
  renderItem: (item: T) => React.ReactNode;
  keyExtractor: (item: T) => string;
}

function DraggableList<T>({
  items,
  setItems,
  renderItem,
  keyExtractor,
}: DraggableListProps<T>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = items.findIndex(
        (item) => keyExtractor(item) === active.id
      );
      const newIndex = items.findIndex(
        (item) => keyExtractor(item) === over?.id
      );
      setItems(arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={items.map(keyExtractor)}
        strategy={verticalListSortingStrategy}
      >
        <div>
          {items.map((item) => (
            <SortableItem
              key={keyExtractor(item)}
              id={keyExtractor(item)}
              item={item}
              renderItem={renderItem}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export default DraggableList;

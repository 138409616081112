import { InputComponentProps } from "../../types";
import { Box } from "@mui/material";

export function CheckboxField({
  field,
  placeholder,
  className,
  cellClassname,
  label,
}: InputComponentProps<boolean>) {
  return (
    <Box
      className={["checkbox-field", className, cellClassname]
        .filter(Boolean)
        .join(" ")}
      display="flex"
      gap={0.5}
      component="label"
      sx={{ cursor: "pointer", small: { fontSize: 14, whiteSpace: "pre" } }}
    >
      <input
        {...field}
        type="checkbox"
        value={""}
        checked={field.value}
        placeholder={placeholder as string}
      />
      <small>{label || field.name}</small>
    </Box>
  );
}

CheckboxField.displayName = "checkbox";

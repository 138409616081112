import React from "react";
import { InputComponentProps } from "../../types";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { API } from "../../../../client/API";
import { getShouldAutoFocus } from "../../../FormSchemaForm/fieldUtils/fieldUtils";

interface SelectFieldProps {
  options: Array<{ value: string; ts: number } | string>;
}

export function EnumField<T = any>({
  field,
  helpers,
  labelProps,
  focused,
  config = {} as any,
  meta,
  size = "medium",
  label,
  ...props
}: InputComponentProps<string> & SelectFieldProps) {
  const { selectOptions = [], options = [], data = {} } = config;
  const { hasError, error } = meta;
  const [fieldOptions, setFieldOptions] = React.useState([
    ...selectOptions,
    ...options,
  ]);
  const { optionsEndpoint } = data;

  const labelSize = size === "small" ? "small" : "normal";

  React.useEffect(() => {
    if (optionsEndpoint) {
      API.get(optionsEndpoint).then((results) => {
        if (Array.isArray(results)) {
          setFieldOptions((prev) => [...prev, ...results]);
        }
      });
    }
  }, [optionsEndpoint]);

  return (
    <FormControl fullWidth>
      <InputLabel
        // color={hasError ? "error" : undefined}
        size={labelSize}
        {...labelProps}
      >
        {label}
      </InputLabel>
      <Select
        // error={hasError}
        autoFocus={getShouldAutoFocus(config, field.value)}
        MenuProps={{
          sx: {
            zIndex: 9999,
          },
        }}
        size={size}
        {...field}
        value={field.value || ""}
        label={label}
      >
        {fieldOptions.map((option) => {
          if (typeof option === "string") {
            return (
              <MenuItem key={option} value={option}>
                {option}{" "}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label || option.value}
            </MenuItem>
          );
        })}
      </Select>
      {hasError && (
        <Box mt={0} ml={1} color="error.main" fontSize="small">
          {error}
        </Box>
      )}
    </FormControl>
  );
}

import { useCallback, useState, useRef, useMemo } from "react";
import { useField, FieldValidator, useFormikContext } from "formik";
import {
  InputComponentProps,
  InputAriaProps,
  InputComponentLabelProps,
  InputComponentMetaProps,
  InputComponentFieldProps,
} from "../FormWrapper/types";
import { FormFieldDto } from "../../dtos";
import { isEmailValid } from "./validators/validators";
import { useFormDto } from "./providers/FormProvider";
import { useIsMobile } from "../../hooks/useIsMobile";
import { TextFieldProps } from "@mui/material";

export interface UseFormFieldOptions {
  validate?: FieldValidator;
  autoSave?: boolean;
}

const BASE_ID = "ladfasdf";

export function getFieldId(name: string) {
  return `${BASE_ID}-${name}`;
}

export function getLabelId(name: string) {
  return `${getFieldId(name)}-label`;
}

export function getAriaLabelledById(name: string) {
  return `${getFieldId(name)}-label`;
}

export function getErrorId(name: string) {
  return `${getFieldId(name)}-error`;
}

export function getLabelProps(
  name: string,
  id: string
): InputComponentLabelProps {
  return {
    id: getLabelId(name),
    htmlFor: getFieldId(name),
  };
}

export function getInputAriaProps<Value = any>(
  name: string,
  meta: InputComponentMetaProps<Value>,
  id: string
): InputAriaProps {
  const { error, submitCount, hasError } = meta;

  const hasHelper = !!error && submitCount > 0;

  const ariaProps: InputAriaProps = {
    id: getFieldId(name),
    "aria-invalid": hasError,
    "aria-labelledby": getLabelId(name),
  };

  if (hasHelper) {
    ariaProps["aria-describedby"] = getErrorId(name);
  }

  return ariaProps;
}

export function getValidatorFn<Value = any>(
  config: FormFieldDto
): FieldValidator | undefined {
  if (!config.isRequired || config.fields.length) return undefined;

  if (config.type === "number") {
    return (value: Value) => {
      const parsedValue = parseFloat(value as any);
      if (isNaN(parsedValue)) {
        return "Please enter a valid number";
      }
    };
  }

  if (config.type === "email") {
    return isEmailValid;
  }

  return (value: Value) => {
    if (!value || (value as string)?.toString()?.trim() === "") {
      return `${config.label} is a required field`;
    }
  };
}
type ExtraProps = {
  parentContext?: any;
};

let count = 0;

export function useFormFieldSchema<TValue>(
  config: FormFieldDto,
  { validate, autoSave }: UseFormFieldOptions = {}
): InputComponentProps<TValue> {
  const id = useRef(config.name).current;
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { fieldSize, fieldSizeMobile } = useFormDto();
  const {
    isSubmitting,
    submitForm,
    submitCount: actualSubmitCount,
    values,
  } = useFormikContext<TValue>() as any;
  const [fieldProps, metaProps, fieldHelpers] = useField<TValue>({
    name: config.name,
    validate: getValidatorFn(config),
  });
  const { value } = fieldProps;
  const prevValue = useRef(fieldProps.value);
  const isMobile = useIsMobile();

  const submitCount = actualSubmitCount;

  const onFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const onBlur = useCallback(() => {
    setIsFocused(false);
    fieldHelpers.setTouched(true, true);
    if (autoSave && !isSubmitting && prevValue.current !== value) {
      prevValue.current = value;
      submitForm();
    }
  }, [autoSave, submitForm, isSubmitting, value, fieldHelpers, validate]);

  const meta = useMemo(() => {
    const { touched, error } = metaProps;
    const hasError = !!error && (touched || submitCount > 0);
    return {
      ...metaProps,
      hasError,
      submitCount,
      isFocused,
    };
  }, [metaProps, isFocused, submitCount]);

  const inputAriaProps = useMemo(() => {
    return getInputAriaProps(config.name, meta, id);
  }, [config.name, meta, id]);

  const field = useMemo(() => {
    return { ...fieldProps, ...inputAriaProps, onBlur, onFocus };
  }, [
    config.name,
    fieldProps,
    meta,
    onBlur,
    onFocus,
    inputAriaProps,
  ]) as any as InputComponentFieldProps<TValue> & InputAriaProps;

  const labelProps = useMemo(() => {
    return getLabelProps(config.name, id);
  }, [config.name]);

  const helpers = useMemo(
    () => ({
      ...fieldHelpers,
      submitForm,
    }),
    [fieldHelpers, submitForm]
  );
  const { name, label: fieldLabel, isRequired } = config;

  const size = useMemo<TextFieldProps["size"]>(() => {
    return isMobile
      ? (fieldSizeMobile as TextFieldProps["size"]) || "small"
      : (fieldSize as TextFieldProps["size"]) || "medium";
  }, [fieldSize, fieldSizeMobile, isMobile]);

  return {
    focused: isFocused,
    field,
    inputAriaProps,
    meta,
    helpers,
    labelProps,
    config,
    size,
    label: config?.isRequired
      ? `${config.label || config.name} *`
      : config.label,
  };
}

import { ReactNode, useCallback, useMemo } from "react";
import { FormDto } from "../../dtos";
import { Formik, FormikProps, useFormikContext } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { WrappedSchemaFormField } from "./WrappedFormField";
import { ValuesUpdater } from "./ValuesUpdater";
import { filterField } from "../FormWrapper/SchemaForm/formUtils";
import { API } from "../../client/API";
import { ErrorsUpdater } from "./ErrorsUpdater";
import { FormProvider } from "./providers/FormProvider";
import usePrevious from "../../hooks/usePrevious";
import { deepEquals } from "../../utils/deepEquals";

type Props<T extends Record<string, any> = Record<string, any>> = {
  form: FormDto;
  initialValues?: T;
  onSubmit?: (values: T) => Promise<void>;
  onChange?: (values: T) => void;
  onErrors?: (values: T) => void;
  onDelete?: () => void;
  deleteBtn?: ReactNode;
  isFieldsOnly?: boolean;
  children?: ReactNode;
  isDefaultSubmit?: boolean;
  isFormDialog?: boolean;
  isDebug?: boolean;
  isReinitializeEnabled?: boolean;
  onSubmitSuccess?: (values: T) => void;
  onSubmitError?: (error: Error) => void;
};

function FormSchemaForm<T extends Record<string, any> = Record<string, any>>({
  form,
  initialValues = {} as T,
  onChange,
  onErrors,
  onSubmit,
  isFieldsOnly,
  deleteBtn,
  children,
  isDefaultSubmit,
  onSubmitError,
  onSubmitSuccess,
  isDebug,
  isReinitializeEnabled = false,
}: Props<T>) {
  const prevValues = usePrevious(initialValues);

  const handleSubmit = async (values: T) => {
    if (onSubmit) {
      await onSubmit?.(values);
      return;
    }
    if (isDefaultSubmit) {
      const { submitUrl, submitMethod, eventName } = form;

      if (submitUrl && submitMethod) {
        const method = submitMethod.toLowerCase() as keyof typeof API;
        if (typeof API[method] === "function") {
          await (API[method] as Function)(submitUrl, values)
            .then((results: any) => {
              window.dispatchEvent(
                new CustomEvent(eventName, { detail: results })
              );
              onSubmitSuccess?.(results);
            })
            .catch((error: any) => {
              onSubmitError?.(error);
            });
        }
      }
    }
  };

  const renderForm = useCallback(
    ({
      submitForm,
      values,
      setFieldValue,
      isValid,
      isSubmitting,
      isValidating,
      errors,
    }: FormikProps<T>) => {
      const { formName, fields = [], submitButtonText } = form;
      const isSubmitDisabled = isSubmitting || isValidating;

      if (isFieldsOnly && isDefaultSubmit) {
        return (
          <Box
            p={1}
            onSubmit={(e) => {
              console.log("e", e);
              e.stopPropagation();
              e.preventDefault();
              submitForm();
            }}
            id={form._id}
            component="form"
          >
            <Grid spacing={2} container>
              {fields.filter(filterField(values)).map((field) => {
                return (
                  <WrappedSchemaFormField key={field.id} fieldConfig={field} />
                );
              })}
              <Box p={2} pb={0}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitDisabled}
                  startIcon={
                    isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : null
                  }
                >
                  {submitButtonText}
                </Button>
              </Box>
            </Grid>
          </Box>
        );
      }

      if (isFieldsOnly) {
        return (
          <>
            <Grid spacing={2} container>
              {fields.filter(filterField(values)).map((field) => {
                return (
                  <WrappedSchemaFormField key={field.id} fieldConfig={field} />
                );
              })}
              {!!onChange && (
                <ValuesUpdater
                  shouldValidate={!!onErrors}
                  onChange={onChange}
                />
              )}
              {!!onErrors && <ErrorsUpdater onErrors={onErrors} />}
            </Grid>
            {deleteBtn}
          </>
        );
      }

      return (
        <Paper
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            submitForm();
          }}
          variant={isFieldsOnly ? "outlined" : "elevation"}
          elevation={3}
        >
          {!isFieldsOnly && (
            <Box p={2}>
              <Typography sx={{ fontWeight: 600, fontSize: `1.35rem` }}>
                {formName}
              </Typography>
            </Box>
          )}

          <Box p={isFieldsOnly ? 1 : 2}>
            <Grid spacing={2} container>
              {fields.filter(filterField(values)).map((field) => {
                return (
                  <WrappedSchemaFormField key={field.id} fieldConfig={field} />
                );
              })}
              {!!onChange && <ValuesUpdater onChange={onChange} />}
            </Grid>
            {deleteBtn}
          </Box>
          {children && (
            <Box px={2} pt={0} pb={2}>
              {children}
            </Box>
          )}

          {(isDefaultSubmit || (!!onSubmit && !isFieldsOnly)) && (
            <Box
              display="flex"
              justifyContent="flex-end"
              gap={2}
              px={2}
              pt={1}
              pb={2}
            >
              <Button
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : null
                }
                disabled={isSubmitDisabled}
                size={form.fieldSize}
                type="submit"
                variant="contained"
              >
                {form.submitButtonText || "Submit"}
              </Button>
            </Box>
          )}
          {isDebug && (
            <Box p={1.5} display="flex" gap={2}>
              <Box
                borderRadius={1}
                p={1.5}
                border="1px solid rgba(100, 100 ,100, 0.3)"
                flex={1}
              >
                <Box>Values:</Box> <pre>{JSON.stringify(values, null, 2)}</pre>
              </Box>
              <Box
                borderRadius={1}
                p={1.5}
                border="1px solid rgba(100, 100 ,100, 0.3)"
                flex={1}
              >
                <Box>Errors:</Box> <pre>{JSON.stringify(errors, null, 2)}</pre>
              </Box>
            </Box>
          )}
        </Paper>
      );
    },
    [form, isFieldsOnly, isDebug, isDefaultSubmit]
  );

  if (!form) {
    return null;
  }

  const isChild = !!(form as any).isChild;

  const enableReinitialize = useMemo(() => {
    return isReinitializeEnabled && !deepEquals(prevValues, initialValues);
  }, [isReinitializeEnabled, initialValues]);

  return (
    <FormProvider formSchema={form}>
      <Formik<T>
        key={form._id}
        enableReinitialize={enableReinitialize}
        validateOnMount={false}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        {renderForm}
      </Formik>
    </FormProvider>
  );
}

export default FormSchemaForm;

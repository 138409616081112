import { ForwardedRef, forwardRef, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { BoxProps, Box, Avatar, IconButton } from "@mui/material";
import { UploadsDto } from "../../dtos";
import { AdminOnly } from "../AdminOnly/AdminOnly";
import { API } from "../../client/API";
import clsx from "clsx";
import { formatBytes } from "../../utils/formatBytes";

type UploadCardProps = {
  upload?: UploadsDto;
  isNew?: boolean;
  variant?: string;
  onDelete?: (id: string) => void;
} & BoxProps;

const renderDimensions = (width: number, height: number) =>
  `${width} x ${height}`;

function UploadCardComponent(
  { upload, className, onDelete, ...boxProps }: UploadCardProps,
  ref?: ForwardedRef<HTMLDivElement>
) {
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const handleDelete = async () => {
    onDelete?.(upload?.id || "");
    await API.delete(`/uploads/${upload?.id}`).then(() => {
      setIsDeleted(true);
    });
  };

  if (isDeleted) {
    return null;
  }

  const { name, alternativeText, caption, width, height, size } = upload || {};

  return (
    <Box
      ref={ref}
      display="flex"
      gap={2}
      boxShadow={1}
      {...boxProps}
      className={clsx("upload-card", className)}
    >
      <Avatar src={upload?.formats?.thumbnail?.url} />
      <Box
        width="100%"
        flex={1}
        gap={0.25}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        <Box
          className="text-overflow"
          fontSize={16}
          component="strong"
          fontWeight={600}
          letterSpacing="-1px"
          textTransform="capitalize"
          width="100%"
        >
          {alternativeText || caption || name}
        </Box>
        <Box display="flex" gap={1}>
          <small className="small">
            {renderDimensions(width || 0, height || 0)}
          </small>
          <small className="small">{formatBytes(size || 0)}</small>
        </Box>
      </Box>
      <AdminOnly>
        {onDelete && (
          <IconButton onClick={handleDelete}>
            <DeleteForeverIcon />
          </IconButton>
        )}
      </AdminOnly>
    </Box>
  );
}

export const UploadCard = forwardRef(UploadCardComponent);

import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIndicator } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import { useResizable } from "react-resizable-layout";
import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  Fragment,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { FormFieldDto } from "../../dtos";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import { findElementDeep } from "../../utils/recursiveUtils";
import { DomRect, getElementRect } from "./sortableListUtils";
import { useBreakPoint } from "../../hooks/useBreakpoint";

type Props = {
  id: string;
  isCollapsed: boolean;
  element: ReturnType<typeof findElementDeep<FormFieldDto>>;
  onRemove: MouseEventHandler<HTMLButtonElement>;
  onEdit: MouseEventHandler<HTMLButtonElement>;
  setOverId: (id: string, action?: string) => void;
  isGhost?: boolean;
  isStyled?: boolean;
  isChildrenAllowed?: boolean;
  handleSaveElement: (element: FormFieldDto) => void;
  children?: ReactNode;
};

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

const getIsOver = (clientX: number, clientY: number, rect: DomRect) => {
  if (
    !rect ||
    rect.left === undefined ||
    rect.right === undefined ||
    rect.top === undefined ||
    rect.bottom === undefined
  )
    return false;
  const isInHorizontalPlain = clientX > rect.left && clientX < rect.right;
  const isInVerticalPlain = clientY > rect.top && clientY < rect.bottom;

  return isInHorizontalPlain && isInVerticalPlain;
};

export const SortableItem: React.FC<Props> = forwardRef(
  (
    {
      id,
      children,
      onEdit,
      onRemove,
      isStyled,
      isCollapsed,
      isChildrenAllowed,
      isGhost,
      element,
      setOverId,
      handleSaveElement,
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const childrenRef = useRef<HTMLDivElement>();
    const provided = useSortable({
      id,
      data: element,
      animateLayoutChanges,
    });
    const breakpoint = useBreakPoint();
    const [gridProps, setGridProps] = useState<any>(
      element?.gridProps || { xs: 12 }
    );
    const {
      position,
      separatorProps,
      isDragging: isDraggingResize,
    } = useResizable({
      axis: "x",
      containerRef: wrapperRef,
      onResizeEnd(args) {
        const parentEl = wrapperRef.current?.parentElement;
        if (!parentEl) return;
        const offsetWidth = parentEl.offsetWidth;
        const newSize = Math.ceil((args.position / offsetWidth) * 12);
        const gridSize = newSize > 12 ? 12 : newSize;

        const nextGridProps = {
          ...gridProps,
          sm: gridSize,
          md: gridSize,
          lg: gridSize,
        };

        setGridProps(nextGridProps);

        handleSaveElement({
          ...(element as FormFieldDto),
          gridProps: nextGridProps,
        });
      },
    });

    const {
      active,
      attributes,
      isDragging,
      isSorting,
      listeners,
      setDraggableNodeRef,
      setDroppableNodeRef,
      isOver,
      transform,
      transition,
      node,
    } = provided;

    useEffect(() => {
      if (!active) {
        return;
      }
      const handleMove = (e: MouseEvent) => {
        if (!wrapperRef.current) {
          return;
        }
        const { clientX, clientY } = e;
        const rect = getElementRect(wrapperRef.current);

        if (active.id === element?.id && rect.left !== undefined) {
          console.log(clientX - rect.left);
          if (clientX - rect.left > 24) {
            // setOverId(active.id, "insidePrevious");
          }
          return;
        }

        if (
          isChildrenAllowed &&
          childrenRef.current &&
          getIsOver(clientX, clientY, rect) &&
          element?.id
        ) {
          setOverId(element.id, "inside");
          return;
        }

        if (getIsOver(clientX, clientY, rect)) {
          console.log(setOverId);
          setOverId(element?.id || "");
        }
      };

      document.addEventListener("mousemove", handleMove, false);

      return () => {
        document.removeEventListener("mousemove", handleMove, false);
      };
    }, [isGhost, isChildrenAllowed, active]);

    const { ancestorIds = [], fields = [], data = {} } = element || {};
    const { flex = "100%" } = data;

    const style: CSSProperties = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    const setRef = useCallback((el: HTMLDivElement) => {
      wrapperRef.current = el;
      if (el) {
        setDroppableNodeRef(el);
        if (typeof ref === "function") {
          ref(el);
        }
      }
    }, []);

    const getMaxWidth = () => {
      return wrapperRef?.current?.parentElement?.offsetWidth;
    };

    return (
      <Fragment>
        <Grid
          item
          ref={setRef}
          style={{}}
          {...gridProps}
          //   mr={`${ancestorIds.length * 24}px`}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            width={
              isDraggingResize
                ? position
                : `calc(100% - ${ancestorIds.length * 24}px)`
            }
            maxWidth={isDraggingResize ? getMaxWidth() : "100%"}
            height="100%"
            overflow="hidden"
            position="relative"
            border="1px solid rgba(100, 100, 100)"
            borderRadius={2}
            ml={`${ancestorIds.length * 24}px`}
            data-parent-id={ancestorIds.join(",")}
            ref={setDraggableNodeRef}
            sx={{
              opacity: isGhost ? 0.4 : 1,
              transition: "opacity ease-in-out .25s",
            }}
          >
            <Box mt="3px" alignItems="center" height={36} display="flex">
              <IconButton {...attributes} {...listeners}>
                <DragIndicator />
              </IconButton>
            </Box>

            <Box minHeight={36}>{children}</Box>

            <Box mr={2} mt="3px" alignItems="center" height={36} display="flex">
              <IconButton onClick={onEdit}>
                <Edit sx={{ fontSize: "1rem" }} />
              </IconButton>
              <IconButton onClick={onRemove}>
                <Delete sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Box>
            <Box
              {...separatorProps}
              sx={{
                cursor: "col-resize",
                height: "100%",
                position: "absolute",
                right: 0,
              }}
            >
              <Box width={5} height="100%" bgcolor="white" />
            </Box>
          </Box>
        </Grid>
        {/* {isChildrenAllowed && !Boolean(fields?.length) && (
          <Box ref={childrenRef} height={50} width="100%" border={1}></Box>
        )} */}
      </Fragment>
    );
  }
);

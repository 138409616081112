type ValueObject = Record<string, any>;

export const getInitialFieldValue = (
  value: string | string[]
): ValueObject[] | string[] => {
  if (Array.isArray(value)) {
    return value;
  }
  if (typeof value === "string") {
    return [value];
  }
  if (typeof value === "object" && value !== null) {
    return [value];
  }
  return [];
};

import { useMediaLibContext } from "../useMediaLibraryProvider";
import { Box, Button } from "@mui/material";
import DraggableList from "../../DraggableList/DraggableList";
import { UploadsDto } from "../../../dtos";
import { getDtoIds } from "../../../utils/getDtoIds";
import { UploadCard } from "../../UploadCard/UploadCard";
import styles from "../MediaLibrary.module.scss";

export const SelectedItemsList = () => {
  const {
    selected,
    setSelectedIds,
    toggleSelection,
    selectAll,
    unselectAll,
    removeUpload,
  } = useMediaLibContext();

  const onReorderImages = (uploads: UploadsDto[]) => {
    setSelectedIds(getDtoIds(uploads));
  };

  const handleRemove = (id: string) => {
    toggleSelection(id);
  };

  return (
    <Box className={styles.selectedItemsList}>
      <Box className={styles.selectedItemsHeader} p={0.5}>
        <b>Selected {selected.length}</b>
        <Button
          className={styles.textButton}
          color="inherit"
          onClick={selectAll}
        >
          Select all
        </Button>
        <Button
          className={styles.textButton}
          color="inherit"
          onClick={unselectAll}
        >
          Unselect all
        </Button>
      </Box>
      <Box className={styles.selectedItems} p={0.5}>
        <DraggableList<UploadsDto>
          items={selected}
          setItems={onReorderImages as any}
          renderItem={(item) => <UploadCard upload={item} />}
          keyExtractor={(item) => item._id}
        />
      </Box>
    </Box>
  );
};

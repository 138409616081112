import React, {
  createContext,
  MutableRefObject,
  useContext,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";

type DialogContextType = {
  isInDialog: false;
  mainRef?: MutableRefObject<HTMLDivElement>;
  footerRef?: MutableRefObject<HTMLDivElement>;
  headerRef?: MutableRefObject<HTMLDivElement>;
};

type ProvidededDialogContextType = {
  isInDialog: true;
  mainRef: MutableRefObject<HTMLDivElement>;
  footerRef?: MutableRefObject<HTMLDivElement>;
  headerRef?: MutableRefObject<HTMLDivElement>;
  onClose?: () => void;
};

type Context = DialogContextType | ProvidededDialogContextType;

const DialogContext = createContext<Context>({ isInDialog: false });

export const DialogProvider: React.FC<
  PropsWithChildren<Omit<ProvidededDialogContextType, "isInDialog">>
> = ({ children, mainRef, footerRef, headerRef, onClose }) => {
  return (
    <DialogContext.Provider
      value={{ isInDialog: true, mainRef, footerRef, headerRef, onClose }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialogContext = () => {
  const context = useContext(DialogContext);

  const [mainElement, setMainElement] = useState<HTMLElement | null>(null);
  const [footerElement, setFooterElement] = useState<HTMLElement | null>(null);
  const [headerElement, setHeaderElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setMainElement(context.mainRef?.current || null);
  }, [context.mainRef?.current]);

  useEffect(() => {
    setHeaderElement(context.headerRef?.current || null);
  }, [context.headerRef?.current]);

  useEffect(() => {
    setFooterElement(context.footerRef?.current || null);
  }, [context.footerRef?.current]);

  return {
    ...context,
    mainElement,
    footerElement,
    headerElement,
  };
};

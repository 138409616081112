import React from "react";
import { UploadsDto } from "../../dtos";
import {
  MediaLibraryProvider,
  useMediaLibrary,
} from "./useMediaLibraryProvider";
import { Box, Button, Container, IconButton, Paper } from "@mui/material";
import { DarkTheme } from "../../pages/Page/PageEditor/DarkTheme/DarkTheme";
import { MediaLibraryList } from "./MediaLibraryList";
import styles from "./MediaLibrary.module.scss";
import { SelectedItemsList } from "./SelectedItemsList/SelectedItemsList";
import { Close } from "@mui/icons-material";

type Props = {
  selected?: Array<string | UploadsDto>;
  maxWidth?: "md" | "lg" | "xl";
  onSelectionChange?: (selected: UploadsDto[]) => void;
  onClose?: () => void;
};

export const MediaLibrary: React.FC<Props> = ({
  selected = [],
  onSelectionChange,
  onClose,
  maxWidth = "lg",
}) => {
  const context = useMediaLibrary(selected);

  const handleSelectionChange = () => {
    onSelectionChange?.(context.selected);
  };

  return (
    <MediaLibraryProvider value={context}>
      <DarkTheme>
        <Container sx={{ height: "80vh" }} maxWidth={maxWidth}>
          <Paper sx={{ height: "100%" }}>
            <Box className={styles.mediaLibrary}>
              <Box className={styles.mediaLibraryHeader}>
                <b>Media library</b>
                {!!onClose && (
                  <IconButton onClick={onClose} className={styles.closeButton}>
                    <Close />
                  </IconButton>
                )}
              </Box>
              <Box className={styles.mediaLibraryContent}>
                <Box className={styles.mediaLibraryList}>
                  <MediaLibraryList />
                </Box>
                <Box className={styles.mediaLibrarySelections}>
                  <SelectedItemsList />
                </Box>
              </Box>
              <Box className={styles.mediaLibraryFooter}>
                <Button variant="contained" onClick={handleSelectionChange}>
                  Apply
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </DarkTheme>
    </MediaLibraryProvider>
  );
};

import { Grid } from "@mui/material";
import React, { ForwardedRef, forwardRef } from "react";
import { FieldValidator } from "formik";
import { FormFieldDto } from "../../dtos";
import { useFormFieldSchema } from "./useFormFieldSchema";
import { getComponent } from "../FormWrapper/SchemaForm/formUtils";
import { ErrorBoundary } from "../ErrorBoundary";
import { InputField } from "./fields/InputField/InputField";

const ErrorWrapper: any = ErrorBoundary;

type WrappedFormFieldProps<TValue> = {
  fieldConfig: FormFieldDto;
  validate?: FieldValidator;
  onChange?: (values: any) => void;
};

const FieldWithFormik = forwardRef(function WrappedFormField<TValue = any>(
  props: WrappedFormFieldProps<TValue>,
  ref?: ForwardedRef<unknown>
) {
  const { fieldConfig, validate, onChange } = props;
  const formFieldProps = useFormFieldSchema<TValue>(fieldConfig || {}, {
    validate,
  });

  const { gridProps = {}, sx = {}, type } = fieldConfig;

  const Component: any = getComponent(fieldConfig) || InputField;

  //   const getFieldClassname = () => {
  //     if (InputComponent.displayName) {
  //       return `field-${InputComponent.displayName}`;
  //     }
  //     if (componentProps.type) {
  //       return `field-${componentProps.type}`;
  //     }
  //     return null;
  //   };

  return (
    <ErrorWrapper>
      <Grid ref={ref as any} item {...gridProps}>
        <Component {...formFieldProps} onChange={onChange} />
      </Grid>
    </ErrorWrapper>
  );
});

export const WrappedSchemaFormField = FieldWithFormik as <TValue = string>(
  props: WrappedFormFieldProps<TValue> & { ref?: React.Ref<HTMLInputElement> }
) => ReturnType<typeof FieldWithFormik>;

import { useEffect, useMemo, useRef } from "react";
import { InputComponentProps, ColumnsProp } from "../../types";
import {
  Box,
  Button,
  capitalize,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import Add from "@mui/icons-material/Add";
import { generateId } from "../../../../utils/generateId";
import { FormFieldDto } from "../../../../dtos";
import { WrappedSchemaFormField } from "../../../FormSchemaForm/WrappedFormField";
import { DeleteForever } from "@mui/icons-material";

interface Props {
  columns?: ColumnsProp;
  isRecursive?: boolean;
  isChild?: boolean;
  isStringValues?: boolean;
  showRecursiveChildren?: (values?: any) => boolean;
}

const getItemTitle = (config: FormFieldDto, value: any = {}, index: number) => {
  const itemLabel = config?.data?.itemLabel || "Item";
  const titleField = config.fields.find((f) => f.isTitleField);
  if (titleField) {
    return value[titleField.name]
      ? capitalize(value[titleField.name])
      : `${itemLabel} ${index + 1}`;
  }
  return `${itemLabel} ${index + 1}`;
};

const mapFieldsRecursively = (
  fields: FormFieldDto[],
  path: string = ""
): FormFieldDto[] => {
  return fields.map((f, index) => {
    const realPath = `${path}.${f.name}`;
    return {
      ...f,
      name: realPath,
      isObjectField: false,
      isFieldsOnly: true,
      fields:
        f.fields.length > 0 ? mapFieldsRecursively(f.fields, realPath) : [],
    };
  });
};

const createArrayItemConfig = (config: FormFieldDto, index: number) => {
  const realPath = `${config.name}[${index}]`;
  const { data = {} } = config;
  return {
    ...config,
    isArray: false,
    isObjectField: false,
    isFieldsOnly: true,
    type: "object",
    data: { ...data, isLabelHidden: true },
    name: realPath,
    isChild: true,
    fields: config.fields.map((f) => ({ ...f, name: `${realPath}.${f.name}` })),
  };
};

export function ArrayField({
  field,
  helpers,
  meta,
  config,
  label,
  size,
}: InputComponentProps<Array<any> | undefined> & Props) {
  const { value = [], name } = field;
  const fieldValues = value;
  const firstRun = useRef(true);
  const { error = [] } = meta;

  const { data = {} } = config;
  const shouldCreateOne = !!data.shouldCreateOne;
  const isHeaderHidden = data.isHeaderHidden?.toString() === "true";
  const { addTooltip, showAddButton, addButtonText = "Add item" } = data;
  const isAddButtonShown = showAddButton?.toString() === "true";

  const hasAllIdFields = useMemo<boolean>(() => {
    return Array.isArray(value) && value.every((val) => !!val.id);
  }, [value]);

  const childrenFields = useMemo(() => {
    if (config.isRecursive) {
      return [...config.fields, { ...config, isChild: true }];
    }
    return config.fields;
  }, [config.fields, config.isRecursive]);

  useEffect(() => {
    if (!hasAllIdFields) {
      helpers.setValue(
        value.map((v) => ({ ...v, id: generateId(field.name) }))
      );
    }
  }, [hasAllIdFields]);

  useEffect(() => {
    if (!value.length && shouldCreateOne && firstRun.current) {
      helpers.setValue([...value, { id: generateId(config.name) }]);
    }
    firstRun.current = false;
  }, [shouldCreateOne, value]);

  const addItem = () => {
    helpers.setValue([...value, { id: generateId(config.name) }]);
  };

  const deleteItem = (id: string) => () => {
    const newValue = value.filter((item) => item.id !== id);
    helpers.setValue(newValue.length ? newValue : []);
  };

  const fieldLabel = label || config.label || field.name;

  return (
    <Grid item xs={12}>
      <Box display="flex" alignItems="center" gap={1}>
        <Typography fontWeight={500}>{fieldLabel}</Typography>
        {!showAddButton && (
          <Tooltip arrow placement="top" title={addTooltip || "Add item"}>
            <IconButton onClick={addItem}>
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {fieldValues.map((val: any, idx: number) => {
        return (
          <Paper
            key={val.id || idx}
            elevation={0}
            sx={{
              p: !isHeaderHidden ? 2 : 0,
              ml: config.isChild ? 2 : 0,
              my: 2,
              border: !isHeaderHidden
                ? "1px solid rgba(100, 100, 100, 0.3)"
                : undefined,
            }}
          >
            {!isHeaderHidden && (
              <Box
                component="div"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                fontWeight={600}
                fontSize={18}
                pb={1.5}
              >
                <span>{getItemTitle(config, val, idx)}</span>
                <Button
                  color="inherit"
                  size="small"
                  variant="outlined"
                  onClick={deleteItem(val.id)}
                >
                  Remove
                </Button>
              </Box>
            )}

            <Box display="flex" gap={2} pt={1}>
              <WrappedSchemaFormField
                fieldConfig={createArrayItemConfig(
                  { ...config, fields: childrenFields },
                  idx
                )}
              />
              {isHeaderHidden && (
                <Tooltip
                  arrow
                  placement="top"
                  title={data.deleteTooltip || "Remove item"}
                >
                  <Box>
                    <Button
                      color="inherit"
                      size={size}
                      variant="outlined"
                      sx={{
                        minHeight: 58,
                        color: "grey.500",
                        "&:hover": {
                          color: "inherit",
                        },
                      }}
                      onClick={deleteItem(val.id)}
                    >
                      <DeleteForever />
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Paper>
        );
      })}
      {isAddButtonShown && (
        <Box mt={isHeaderHidden && !!value.length ? -2 : -1}>
          <Button
            size="small"
            startIcon={<Add />}
            color="inherit"
            sx={{
              color: "grey.700",
              "&:hover": {
                color: "inherit",
              },
            }}
            onClick={addItem}
          >
            {addButtonText}
          </Button>
        </Box>
      )}
    </Grid>
  );
}

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Paper,
  Chip,
  InputAdornment,
  CircularProgress,
  Typography,
  Box,
  Alert,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { InputComponentProps } from "../../../FormWrapper/types";
import { API } from "../../../../client/API";
import { SuggestionDto } from "../../../../dtos";
import { getDtoMap } from "../../../../utils/getDtoMap";
import { getDtoIds } from "../../../../utils/getDtoIds";
import { getLabelSize } from "../../fieldUtils/fieldUtils";
import { stringSimilarity } from "../../../../utils/stringSimilarity";
import useToggle from "../../../../hooks/useToggle";
import { FormDialog } from "../../../FormDialog/FormDialog";
import { getInitialFieldValue } from "./autoCompleteUtils";
import { InfiniteScrollerPanel } from "./InfiniteScrollerPanel/InfiniteScrollerPanel";

export const CustomAutoCompleteField = ({
  field,
  helpers,
  meta,
  config,
  labelProps,
  label,
  size,
  inputAriaProps,
}: InputComponentProps<string | string[]>) => {
  const { collectionRef, collectionFormId, isArray } = config;
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [titleField, setTitleField] = useState<string>("");
  const [suggestions, setSuggestions] = useState<SuggestionDto[]>([]);
  const [selectedValues, setSelectedValues] = useState<SuggestionDto[]>([]);

  const [
    isCreatingNew,
    toggleIsCreatingNew,
    openCreateDialog,
    closeCreateDialog,
  ] = useToggle(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const fetchSuggestions = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    await API.get(`/options/v2/${collectionRef}`).then(
      ({ options, titleField }) => {
        setTitleField(titleField);
        setSuggestions(options);
      }
    );
    setIsLoading(false);
  }, [isLoading, isOpen]);

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const suggestionsMap = useMemo(() => {
    return getDtoMap(suggestions, "_id");
  }, [suggestions]);

  const selectedIds = useMemo<string[]>(() => {
    const initialValues = getInitialFieldValue(field.value);
    return getDtoIds(initialValues as any);
  }, [field.value]);

  useEffect(() => {
    setSelectedValues(
      selectedIds
        .filter((v) => !!suggestionsMap[v])
        .map((v) => suggestionsMap[v])
    );
  }, [selectedIds, suggestionsMap]);

  const isSelectedValueInputValue = useMemo(() => {
    return selectedValues.some((v) => v.label === inputValue);
  }, [inputValue, collectionFormId]);

  useEffect(() => {
    if (!isSelectedValueInputValue && inputValue.length) {
      setIsOpen(true);
      setSelectedIndex(0);
    }
  }, [isSelectedValueInputValue, inputValue, isArray]);

  const handleSelect = (suggestion: SuggestionDto) => {
    if (suggestion) {
      if (isArray) {
        helpers.setValue([...selectedIds, suggestion._id], true);
        handleClearInput();
      } else {
        helpers.setValue(suggestion._id, true);
        handleClearInput();
      }
      setIsOpen(false);
    } else {
      openCreateDialog();
      setIsOpen(false);
    }
  };

  const filteredSuggestions = useMemo<SuggestionDto[]>(() => {
    if (!inputValue && !selectedValues.length) return suggestions;

    const selectedIds = selectedValues.map((v) => v._id);

    const nonSelectedFilters = suggestions.filter(
      (v) => !selectedIds.includes(v._id)
    );

    if (!inputValue) {
      return nonSelectedFilters;
    }

    return nonSelectedFilters
      .filter((suggestion) =>
        suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((s) => ({ ...s, similarity: stringSimilarity(inputValue, s.label) }))
      .sort((a, b) => b.similarity - a.similarity);
  }, [inputValue, selectedValues, suggestions]);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      if (!isOpen) {
        setIsOpen(true);
        return;
      }
      if (!filteredSuggestions.length) {
        setSelectedIndex(0);
        return;
      }
      setSelectedIndex((prev) =>
        prev < filteredSuggestions.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (!filteredSuggestions.length) {
        setSelectedIndex(-1);
        return;
      }
      setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
    } else if (e.key === "Enter" && selectedIndex >= 0) {
      e.preventDefault();
      handleSelect(filteredSuggestions[selectedIndex]);
    } else if (e.key === "Escape") {
      e.preventDefault();
      setIsOpen(false);
    } else if (e.key === "Backspace" && isSelectedValueInputValue) {
      // handleRemoveItem(selectedItems[selectedItems.length - 1]);
    } else if (e.key === "Tab") {
      setIsOpen(false);
    }
  };

  const handleRemoveItem = (suggestionId: string) => {
    if (isArray) {
      helpers.setValue(
        selectedIds.filter((v) => v !== suggestionId),
        false
      );
    } else {
      helpers.setValue("", false);
    }
  };

  const handleClearInput = () => {
    setInputValue("");
    setIsOpen(false);
  };

  const highlightMatch = (text: string) => {
    if (!inputValue) return text;

    const parts = text.split(new RegExp(`(${inputValue})`, "gi"));
    return (
      <Box component="span">
        {parts.map((part, i) => (
          <Box
            component="span"
            key={i}
            sx={
              part.toLowerCase() === inputValue.toLowerCase()
                ? {
                    backgroundColor: "yellow",
                    fontWeight: "bold",
                  }
                : {}
            }
          >
            {part}
          </Box>
        ))}
      </Box>
    );
  };

  const handleSubmitSuccess = (values: any) => {
    const { [titleField]: label, _id, value } = values;
    if (isArray) {
      helpers.setValue([...selectedIds, _id], false);
      handleClearInput();
    } else {
      helpers.setValue(_id, false);
      handleClearInput();
    }
    setSuggestions((prev) => [...prev, { _id, label, value: value || _id }]);
  };

  const isCreateButtonShown = useMemo(() => {
    return !!collectionFormId && !isSelectedValueInputValue;
  }, [selectedValues, isSelectedValueInputValue]);

  return (
    <>
      <Box
        onFocus={() => setIsOpen(true)}
        ref={containerRef}
        sx={{ width: "100%", position: "relative" }}
      >
        <Box sx={{ mb: meta.hasError ? 1 : 0 }}>
          <FormControl fullWidth variant="outlined">
            {selectedValues.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  position: "absolute",
                  top: "-50%",
                  left: "50%",
                  transform: "translate(-50%, 50%)",
                  gap: 0.5,
                  pb: 1,
                  zIndex: 99,
                  maxWidth: "75%",
                }}
              ></Box>
            )}
            <InputLabel
              {...labelProps}
              sx={{ color: "text.secondary" }}
              // color={hasError ? "error" : undefined}
              size={getLabelSize(size || "medium")}
            >
              {label || field.name}
            </InputLabel>
            <OutlinedInput
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={handleKeyDown}
              inputProps={{
                ...inputAriaProps,
                autoComplete: "off",
              }}
              startAdornment={
                <InputAdornment position="start">
                  {selectedValues.map((item) => (
                    <Chip
                      key={item._id}
                      label={item.label}
                      tabIndex={-1}
                      onDelete={() => handleRemoveItem(item._id)}
                      size="small"
                    />
                  ))}
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {inputValue && (
                    <IconButton
                      size="small"
                      tabIndex={-1}
                      onClick={handleClearInput}
                      edge="end"
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                  {isLoading && isOpen && <CircularProgress size={20} />}
                </InputAdornment>
              }
              label={label}
              fullWidth
              size={size}
            />
          </FormControl>
        </Box>

        {meta.error && meta.hasError && (
          <Alert severity="error" sx={{ mb: 1 }}>
            {meta.error}
          </Alert>
        )}

        <InfiniteScrollerPanel
          isOpen={isOpen && filteredSuggestions.length > 0}
          onClose={() => {
            setIsOpen(false);
          }}
          containerElement={containerRef.current as HTMLDivElement}
          items={filteredSuggestions}
          itemHeight={44}
          renderItem={(item, index) => {
            return (
              <Box
                key={item._id}
                onClick={() => {
                  handleSelect(item);
                }}
                sx={{
                  px: 2,
                  py: 1,
                  cursor: "pointer",
                  backgroundColor:
                    index === selectedIndex ? "action.hover" : "inherit",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                }}
              >
                {highlightMatch(item.label)}
              </Box>
            );
          }}
        />

        {isOpen &&
          !isLoading &&
          inputValue.length > 0 &&
          filteredSuggestions.length === 0 &&
          !isSelectedValueInputValue && (
            <Paper
              elevation={3}
              sx={{
                position: "absolute",
                width: "100%",
                mt: 0.5,
                zIndex: 1000,
              }}
            >
              <Box sx={{ p: 1 }}>
                {!!isCreateButtonShown ? (
                  <Button
                    fullWidth
                    variant={selectedIndex > -1 ? "contained" : "text"}
                    color="inherit"
                    onClick={toggleIsCreatingNew}
                  >
                    <span>
                      Create new <b>{inputValue}</b>
                    </span>
                  </Button>
                ) : (
                  <Typography>No results found</Typography>
                )}
              </Box>
            </Paper>
          )}
      </Box>
      {collectionFormId && (
        <FormDialog
          isOpen={isCreatingNew}
          onClose={toggleIsCreatingNew}
          formSlug={collectionFormId}
          initialValues={!!titleField ? { [titleField]: inputValue } : {}}
          onSubmitSuccess={handleSubmitSuccess}
        />
      )}
    </>
  );
};

import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { InputComponentProps } from "../types";
import {
  buildSxPropsArray,
  buildSxPropsFromArray,
  getValueFromResponsiveField,
} from "./SxField/helpers";
import { generateId } from "../../../utils/generateId";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { clsx } from "clsx";
import Add from "@mui/icons-material/Add";
import { useSheetKeyboardEvents } from "./useSheetKeyboardEvents";

type FieldValue = number | string;

export type PropItem = {
  id: string;
  property: string;
  value: FieldValue;
  responsive?: boolean;
};

const createNewItem = (): PropItem => {
  return { id: generateId("prop"), property: "", value: "" };
};

const responsiveSx = {
  display: "flex",
  flexDirection: "column",
};

export function MixedPropsField(
  props: InputComponentProps<Record<string, any>>
) {
  const { field, helpers, config } = props;
  const { value: mixedProps = {} } = field;
  const sheetRef = useRef<HTMLDivElement>(null);
  const [valuesArray, setValuesArray] = useState<PropItem[]>(() => {
    const vals = buildSxPropsArray(mixedProps) as PropItem[];
    return vals.length ? vals : [createNewItem()];
  });

  useEffect(() => {
    const nextValue = buildSxPropsFromArray(valuesArray);
    helpers.setValue(nextValue as any);
  }, [valuesArray]);

  const addItem = () => {
    setValuesArray((prev) => [...prev, createNewItem()]);
  };

  useSheetKeyboardEvents(sheetRef as any, addItem, [valuesArray]);

  const removeItem = (id: string) => {
    setValuesArray((prev) => prev.filter((item) => item.id !== id));
  };

  const handleChange = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValuesArray((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, [e.target.name]: e.target.value };
        }
        return item;
      })
    );
  };

  const handleResponsiveChange = (id: string) => () => {
    setValuesArray((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const isResponsive = !item.responsive;
          return {
            ...item,
            responsive: isResponsive,
            value: isResponsive
              ? { xs: item.value }
              : (getValueFromResponsiveField(item.value as any) as any),
          };
        }
        return item;
      })
    );
  };

  return (
    <Box>
      <Box display="flex" fontSize="1rem" alignItems="center" gap={1} mb={1}>
        <span>{config.label}</span>
        <Tooltip placement="top" arrow title="Add property">
          <span>
            <IconButton className="outlined" onClick={addItem} size="small">
              <Add />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box
        className="sx-field"
        ref={sheetRef}
        component={Paper}
        variant="outlined"
        sx={{ fontSize: 12, input: { fontSize: 12 } }}
        border="1px solid rgba(255, 255, 255, 0.23)"
      >
        {valuesArray.map((sxProp) => {
          const { id, property, value, responsive } = sxProp;
          return (
            <Box key={id} width="100%" display="flex">
              <Box flex="0 0 auto" pt={0.75} px={0.5} className="outline-box">
                <IconButton
                  name="remove"
                  size="small"
                  onClick={() => removeItem(id)}
                >
                  <DeleteForever sx={{ fontSize: 14 }} />
                </IconButton>
              </Box>
              <Box
                className={clsx("outline-box", {
                  "outline-box-responsive": responsive,
                })}
                flex={1}
              >
                <Box className="outline-box-child">
                  <TextField
                    autoFocus={!property}
                    name="property"
                    placeholder="property"
                    value={property}
                    onChange={handleChange(id)}
                    sx={{ ".MuiInputBase-root": { borderRadius: 0 } }}
                    fullWidth
                    size="small"
                  />
                </Box>
              </Box>
              <Box
                className="outline-box"
                flex={1}
                sx={responsive ? responsiveSx : undefined}
              >
                <Box className="outline-box-child">
                  <TextField
                    name="value"
                    value={value}
                    onChange={handleChange(id)}
                    fullWidth
                    sx={{ ".MuiInputBase-root": { borderRadius: 0 } }}
                    size="small"
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                flex="0 0 auto"
                className="outline-box"
              >
                <Box p="0.35rem 0.5rem" className="outline-box-child">
                  <Checkbox
                    disableRipple
                    size="small"
                    checked={responsive}
                    onClick={handleResponsiveChange(id)}
                    inputProps={{ "aria-label": `${id}-responsive` }}
                    name="isObject"
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

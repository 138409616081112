import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { UploadsDto } from "../../dtos";
import useToggle from "../../hooks/useToggle";
import { getDtoIds } from "../../utils/getDtoIds";
import { API } from "../../client/API";

const MediaLibraryContext = createContext<
  undefined | ReturnType<typeof useMediaLibrary>
>(undefined);

export const useMediaLibrary = (selected: Array<UploadsDto | string> = []) => {
  const [isLoading, toggleLoading] = useToggle();
  const [uploads, setUploads] = useState<UploadsDto[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>(getDtoIds(selected));

  const fetchUploads = useCallback(async () => {
    toggleLoading();
    await API.get("/uploads", { sort: { ts: "desc" } }).then(setUploads);
    toggleLoading();
  }, []);

  useEffect(() => {
    setSelectedIds(getDtoIds(selected));
  }, [selected]);

  useEffect(() => {
    fetchUploads();
  }, []);

  const toggleSelection = useCallback(
    (id: string) => {
      return selectedIds.includes(id)
        ? setSelectedIds(selectedIds.filter((i) => i !== id))
        : setSelectedIds([...selectedIds, id]);
    },
    [selectedIds]
  );

  const selectAll = useCallback(() => {
    setSelectedIds(getDtoIds(uploads));
  }, [uploads]);

  const unselectAll = useCallback(() => {
    setSelectedIds([]);
  }, []);

  const removeUpload = useCallback(
    async (id: string) => {
      await API.delete(`/uploads/${id}`);
      setUploads(uploads.filter((upload) => upload._id !== id));
      setSelectedIds(selectedIds.filter((i) => i !== id));
    },
    [uploads]
  );

  const addUploads = useCallback(
    (newUploads: UploadsDto[]) => {
      const updatedUploads = [...uploads, ...newUploads];
      setUploads(updatedUploads);
    },
    [uploads]
  );

  return {
    uploads,
    selectedIds,
    selected: selectedIds.map(
      (id) => uploads.find((upload) => upload._id === id) || id
    ) as UploadsDto[],
    toggleSelection,
    selectAll,
    setSelectedIds,
    unselectAll,
    removeUpload,
    addUploads,
  };
};

export const useMediaLibContext = () => {
  const context = useContext(MediaLibraryContext);

  if (!context) {
    throw new Error("Media LibraryContext not set up");
  }

  return context;
};

type Props = { value: ReturnType<typeof useMediaLibrary> };

export const MediaLibraryProvider: React.FC<PropsWithChildren<Props>> = ({
  value,
  children,
}) => {
  return (
    <MediaLibraryContext.Provider value={value}>
      {children}
    </MediaLibraryContext.Provider>
  );
};

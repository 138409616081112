import React from "react";
import { FixedSizeList } from "react-window";
import { SuggestionDto } from "../../../../../dtos";
import { Popper, ClickAwayListener, Paper } from "@mui/material";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  containerElement: HTMLDivElement;
  items: SuggestionDto[];
  renderItem: (item: SuggestionDto, index: number) => React.ReactNode;
  itemHeight: number;
};

export const InfiniteScrollerPanel = ({
  isOpen,
  onClose,
  containerElement,
  items,
  renderItem,
  itemHeight,
}: Props) => {
  if (!containerElement) return null;

  const handleClose = (event: MouseEvent) => {
    if (!containerElement.contains(event.target as HTMLElement)) {
      onClose();
    }
  };

  const minWidth = containerElement.offsetWidth;

  const getHeight = () => {
    if (items.length * itemHeight < 300) {
      return items.length * itemHeight || itemHeight;
    }
    return 300;
  };

  return (
    <Popper
      sx={{ zIndex: 9999 }}
      open={isOpen}
      anchorEl={containerElement}
      placement="bottom-start"
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ]}
    >
      <ClickAwayListener onClickAway={handleClose as any}>
        <Paper
          sx={{
            minWidth,
            border: "1px solid rgba(100,100,100,.32)",
            borderBottom: "none",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <FixedSizeList
            itemSize={itemHeight}
            height={getHeight()}
            width="100%"
            itemCount={items.length}
          >
            {({ index, style }) => {
              return (
                <div style={style} key={items[index]._id}>
                  {renderItem(items[index], index)}
                </div>
              );
            }}
          </FixedSizeList>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

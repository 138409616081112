import React, { useEffect } from "react";
import { InputComponentProps } from "../../../FormWrapper/types";
import { Box, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { FormFieldDto } from "../../../../dtos";
import clsx from "clsx";
import { getShouldAutoFocus } from "../../fieldUtils/fieldUtils";

type Props = InputComponentProps<any>;

export const InputField: React.FC<InputComponentProps<any>> = ({
  field,
  placeholder,
  label,
  labelProps,
  inputAriaProps,
  meta,
  helpers,
  config = {} as FormFieldDto,
  size,
}) => {
  const { data = {}, type, isAutoFocus, conditions } = config;
  const isMultiline = type === "textarea";
  const { hasError, error } = meta;
  const { minHeight, defaultValue } = data;
  const labelSize = size === "medium" ? "normal" : "small";

  useEffect(() => {
    if (type === "number" && defaultValue && !field.value) {
      helpers.setValue(parseInt(defaultValue, 10));
    }
  }, [defaultValue, field.value, type]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel
        {...labelProps}
        sx={{ color: "text.secondary" }}
        // color={hasError ? "error" : undefined}
        size={labelSize}
      >
        {label || field.name}
      </InputLabel>
      <OutlinedInput
        {...field}
        type={config.type}
        autoFocus={getShouldAutoFocus(config, field.value)}
        // error={hasError}
        multiline={isMultiline}
        value={field.value || ""}
        inputProps={{
          ...inputAriaProps,
          min: data.minValue,
          max: data.maxValue,
          autoFocus: isAutoFocus,
          sx: { minHeight: isMultiline ? minHeight : undefined },
        }}
        label={label}
        fullWidth
        className={clsx("default-field", config?.data?.className)}
        placeholder={placeholder as string}
        size={size}
      />
      {hasError && (
        <Box mt={0.25} ml={1} color="error.main" fontSize="small">
          {error}
        </Box>
      )}
    </FormControl>
  );
};

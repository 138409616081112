import { Box, Button } from "@mui/material";
import { useMediaLibContext } from "./useMediaLibraryProvider";
import { getImageProps } from "../../utils/getImageProps";
import styles from "./MediaLibrary.module.scss";
import clsx from "clsx";

export const MediaLibraryList = () => {
  const { uploads, selectedIds, toggleSelection, removeUpload } =
    useMediaLibContext();
  return (
    <Box display="flex" flexWrap="wrap">
      {uploads.map((upload) => {
        return (
          <Box key={upload._id} className={styles.listItemCard}>
            <div
              onClick={() => toggleSelection(upload._id)}
              className={clsx(styles.listItem, {
                [styles.listItemSelected]: selectedIds.includes(upload._id),
              })}
            >
              <img {...getImageProps(upload, "thumbnail")} srcSet={undefined} />
            </div>
            <div className={styles.listItemActions}>
              <Button
                onClick={() => removeUpload(upload._id)}
                color="error"
                className={styles.textButton}
              >
                Delete
              </Button>
            </div>
          </Box>
        );
      })}
    </Box>
  );
};

import React from "react";
import EditableName from "../../../EditableName/EditableName";
import { InputComponentProps } from "../../types";

export const EditableNameField: React.FC<InputComponentProps<string>> = ({
  field,
  helpers,
  label,
}) => {
  return <EditableName label={field.value || ""} setLabel={helpers.setValue} />;
};

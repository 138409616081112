import { FC, useEffect, useState } from "react";
import { InputComponentProps } from "../../types";
import { UploadButton } from "../../../UploadButton/UploadButton";
import { UploadsDto } from "../../../../dtos";
import { API } from "../../../../client/API";
import { getDtoMap } from "../../../../utils/getDtoMap";
import { Box, Dialog, IconButton, Tooltip } from "@mui/material";
import DraggableList from "../../../DraggableList";
import CollectionsIcon from "@mui/icons-material/Collections";
import useToggle from "../../../../hooks/useToggle";
import { MediaLibrary } from "../../../MediaLibrary/MediaLibrary";
import { UploadCard } from "../../../UploadCard/UploadCard";

const getButtonHeight = (size: string) => {
  return size === "medium" ? 52 : 38;
};

export const ImageUploadButton: FC<
  InputComponentProps<UploadsDto | UploadsDto[]>
> = ({ field, meta, helpers, config, size }) => {
  const [uploadMap, setUploadsMap] = useState<Record<string, UploadsDto>>({});
  const [isMediaLibOpen, toggleMediaLibrary] = useToggle(false);
  const { data = {} } = config;
  const isGalleryHidden = data.isGalleryHidden?.toString() === "true";

  useEffect(() => {
    API.get("/uploads")
      .then((uploads: UploadsDto[]) => {
        setUploadsMap(getDtoMap(uploads));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const initialValues = Array.isArray(field.value)
    ? field.value
    : [field.value].filter(Boolean);

  const handleUploadComplete = (uploads: UploadsDto[]) => {
    if (Array.isArray(field.value) || config.isArray) {
      const currentImages = initialValues || [];
      helpers.setValue([...currentImages, ...uploads], false);
      return;
    }
    const [upload] = uploads;
    if (upload) {
      helpers.setValue(upload, false);
    }
  };

  const handleMediaLibraryChange = (selected: UploadsDto[]) => {
    if (Array.isArray(field.value) || config.isArray) {
      helpers.setValue([...selected], false);
    } else {
      helpers.setValue(selected[0], false);
    }
    toggleMediaLibrary();
  };

  const handleReorderImages = (newValues: UploadsDto[]) => {
    if (Array.isArray(field.value) || config.isArray) {
      helpers.setValue(newValues, false);
    } else {
      helpers.setValue(newValues[0], false);
    }
  };

  const handleDelete = (id: string) => {
    if (Array.isArray(field.value) || config.isArray) {
      helpers.setValue(
        initialValues.filter((val) => val._id !== id),
        false
      );
    } else {
      helpers.setValue(null as any, false);
    }
  };

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <UploadButton
          onUploadComplete={handleUploadComplete}
          initialValues={initialValues}
          variant="outlined"
          size="large"
          sx={{
            height: getButtonHeight(size || "medium"),
            textTransform: "none",
            color: "grey.700",
            borderColor: "grey.600",
            "&:hover": {
              color: "inherit",
            },
          }}
        >
          {config.label}
        </UploadButton>
        {!isGalleryHidden && (
          <Tooltip placement="top" title="Media Library">
            <IconButton
              sx={{ fontSize: "0.85rem", svg: { fontSize: "1.2rem" } }}
              onClick={toggleMediaLibrary}
            >
              <CollectionsIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <DraggableList
        items={initialValues}
        setItems={handleReorderImages as any}
        renderItem={(item) => (
          <UploadCard upload={item as UploadsDto} onDelete={handleDelete} />
        )}
        keyExtractor={(item) => (item as UploadsDto)._id}
      />

      <Dialog
        PaperComponent={() => (
          <MediaLibrary
            selected={initialValues}
            onClose={toggleMediaLibrary}
            onSelectionChange={handleMediaLibraryChange}
          />
        )}
        open={isMediaLibOpen}
        onClose={toggleMediaLibrary}
      />
    </Box>
  );
};

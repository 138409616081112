import { getUniqueArray } from "./getUniqueArray";

type BaseOptionDto = {
  id: string;
};

type BaseDto = {
  _id: string;
};

type BDto = BaseDto | BaseOptionDto;

const isBaseDto = (item: BDto): item is BaseDto => {
  return !!(item as BaseDto)._id;
};

export const getDtoIds = <TDto extends BDto = BDto>(
  items: Array<TDto | string>
) => {
  const ids = items.map((item: TDto | string) =>
    typeof item === "string" ? item : isBaseDto(item) ? item._id : item.id
  );
  return getUniqueArray(ids);
};

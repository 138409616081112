import { ArrayField } from "../fields/ArrayField/ArrayField";
import { ObjectField } from "../fields/ObjectField/ObjectField";
import { EnumField } from "../fields/EnumField/EnumField";
import { JSONField } from "../fields/TextareaField/JsonField";
import { CheckboxField } from "../fields/CheckboxField/CheckboxField";
import { FormFieldDto, FieldConditionDto } from "../../../dtos";
import { EditableNameField } from "../fields/EditableNameField/EditableNameField";
import { SxPropsField } from "../fields/SxField/SxField";
import { MixedPropsField } from "../fields/MixedField";
import { ImageUploadButton } from "../fields/ImageUploadField/ImageUploadField";
import { SortableList } from "../../SortableList/SortableList";
import {
  CustomAutoCompleteField,
  PasswordField,
} from "../../FormSchemaForm/fields";
import GoogleAuth from "../../GoogleAuth/GoogleAuth";

export const getComponent = (field: FormFieldDto) => {
  if (field.name === "sx") {
    return SxPropsField;
  }
  switch (field.type?.toLowerCase()) {
    case "id":
      return;
    case "uid":
      return EditableNameField;
    case "password":
      return PasswordField;
    case "boolean":
      return CheckboxField;
    case "json":
      return JSONField;
    case "object":
      return field.isArray
        ? field.isRecursive && field.data?.component === "SortableList"
          ? SortableList
          : ArrayField
        : ObjectField;
    case "ref": {
      if (field.collectionRef === "Uploads") {
        return ImageUploadButton;
      }
      return CustomAutoCompleteField;
    }
    case "enum":
    case "select":
      return EnumField;
    case "sx":
      return SxPropsField;
    case "mixed":
      return MixedPropsField;
    case "media":
    case "image":
      return ImageUploadButton;
    case "googleauth":
      return GoogleAuth;
    default:
      return undefined;
  }
};

export const getType = (field: FormFieldDto) => {
  switch (field.type) {
    case "number":
      return "number";
    case "boolean":
      return "checkbox";
    case "date":
      return "date";
    default:
      return field.type;
  }
};

const equal = (val1: any, val2: any) => {
  return val1?.toString() === val2?.toString();
};

const notEqual = (val1: any, val2: any) => {
  return val1 !== val2;
};

const or = (conditions: FieldConditionDto[], values: any): boolean => {
  return conditions.some((condition) => {
    const { column, operator, value } = condition;
    const compareFn = getCompareFn(operator);
    const currentValue = values[column];
    return compareFn(currentValue, value);
  });
};

const getCompareFn = (operator: string) => {
  switch (operator) {
    case "eq":
      return equal;
    case "neq":
      return notEqual;
    case "or":
      return or;
    default:
      return () => true;
  }
};

const isAndOrOr = (operator: string) => {
  return operator === "and" || operator === "or";
};

export const filterField =
  (values: any = {}) =>
  (field: FormFieldDto & { isNew?: boolean }) => {
    const { conditions = [] } = field;

    if (field.isNew) {
      return false;
    }

    if (!conditions?.length) {
      return true;
    }

    return conditions.every((condition) => {
      const {
        column,
        operator,
        value,
        conditions: childConditions = [],
      } = condition;

      const checkFn = getCompareFn(operator) as any;

      if (isAndOrOr(operator)) {
        return checkFn(childConditions, values);
      }

      if (!column) {
        return true;
      }

      return checkFn(values[column], value, childConditions, values);
    });
  };

import { useFormikContext } from "formik";
import { useCallback, useEffect, useRef } from "react";

export function ErrorsUpdater<TValue>({
  onErrors = (errors: any) => {},
  debounceTime = 250,
}) {
  const { errors, values, validateForm, isValidating } =
    useFormikContext<TValue>();
  const prevValues = useRef(values);
  const onChangeRef = useRef(onErrors);

  useEffect(() => {
    onChangeRef.current = onErrors;
  }, [onErrors]);

  const validate = useCallback(async (vals: any) => {
    const errors = await validateForm(vals);
    console.log(new Date().toLocaleString(), errors);
    onChangeRef.current?.({ ...errors });
  }, []);

  useEffect(() => {
    validate(values);
    prevValues.current = values;
  }, [values, errors]);

  return null;
}

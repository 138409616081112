import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  SxProps,
  TextField,
  Tooltip,
} from "@mui/material";
import { InputComponentProps } from "../../types";
import {
  buildSxPropsArray,
  buildSxPropsFromArray,
  getValueFromResponsiveField,
} from "./helpers";
import { generateId } from "../../../../utils/generateId";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { clsx } from "clsx";
import { Add } from "@mui/icons-material";
import { useSheetKeyboardEvents } from "../useSheetKeyboardEvents";

type FieldValue = number | string;

type ResponsiveFieldValue = {
  xs?: FieldValue;
  sm?: FieldValue;
  md?: FieldValue;
  lg?: FieldValue;
  xl?: FieldValue;
};

export type SxItem = {
  id: string;
  property: string;
  value: FieldValue | ResponsiveFieldValue;
  responsive?: boolean;
};

const createNewItem = () => {
  return { id: generateId("sx"), type: "string", property: "", value: "" };
};

const RESPONSIVE_PROPS: Array<keyof ResponsiveFieldValue> = [
  "xs",
  "sm",
  "md",
  "lg",
];

const responsiveSx = {
  display: "flex",
  flexDirection: "column",
};

export function SxPropsField(props: InputComponentProps<SxProps>) {
  const sheetRef = useRef<HTMLDivElement>(null);
  const { field, helpers, config, label } = props;
  const { value: sxProps = {} } = field;
  const [valuesArray, setValuesArray] = useState<SxItem[]>(() => {
    const vals = buildSxPropsArray(sxProps);
    return vals.length ? vals : [createNewItem()];
  });

  useEffect(() => {
    const nextValue = buildSxPropsFromArray(valuesArray);
    helpers.setValue(nextValue);
  }, [valuesArray]);

  const addItem = () => {
    setValuesArray((prev) => [...prev, createNewItem()]);
  };

  useSheetKeyboardEvents(sheetRef as any, addItem, [valuesArray]);

  const removeItem = (id: string) => {
    setValuesArray((prev) => prev.filter((item) => item.id !== id));
  };

  console.log("sxProps=", sxProps);

  const handleChange = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.name, e.target.value);
    setValuesArray((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, [e.target.name]: e.target.value };
        }
        return item;
      })
    );
  };

  const handleResponsiveChangeInput =
    (id: string, prop: keyof ResponsiveFieldValue) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setValuesArray((prev) => {
        return prev.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              value: {
                ...(item.value as ResponsiveFieldValue),
                [prop]: e.target.value,
              },
            };
          }
          return item;
        });
      });
    };

  const handleResponsiveChange = (id: string) => () => {
    setValuesArray((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          const isResponsive = !item.responsive;
          return {
            ...item,
            responsive: isResponsive,
            value: isResponsive
              ? { xs: item.value }
              : (getValueFromResponsiveField(item.value as any) as any),
          };
        }
        return item;
      })
    );
  };

  return (
    <Box>
      <Box display="flex" fontSize="1rem" alignItems="center" gap={1} mb={1}>
        <span>{config?.label || label || field.name}</span>
        <Tooltip placement="top" arrow title="Add property">
          <span>
            <IconButton
              name="remove"
              className="outlined"
              onClick={addItem}
              size="small"
            >
              <Add />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <Box
        className="sx-field"
        ref={sheetRef}
        sx={{ fontSize: 12, input: { fontSize: 12 } }}
      >
        {valuesArray.map((sxProp) => {
          const { id, property, value, responsive } = sxProp;
          return (
            <Box key={id} width="100%" display="flex">
              <Box
                borderRadius={2}
                flex="0 0 auto"
                pt={0.75}
                px={0.5}
                className="outline-box"
              >
                <IconButton size="small" onClick={() => removeItem(id)}>
                  <DeleteForever sx={{ fontSize: 14 }} />
                </IconButton>
              </Box>
              <Box
                className={clsx("outline-box", {
                  "outline-box-responsive": responsive,
                })}
                flex={1}
              >
                <Box className="outline-box-child">
                  <TextField
                    autoFocus={!property && !!sheetRef.current}
                    name="property"
                    placeholder="property"
                    value={property}
                    onChange={handleChange(id)}
                    sx={{ ".MuiInputBase-root": { borderRadius: 0 } }}
                    fullWidth
                    size="small"
                  />
                </Box>
              </Box>
              <Box
                className="outline-box"
                flex={1}
                sx={responsive ? responsiveSx : undefined}
              >
                {responsive ? (
                  RESPONSIVE_PROPS.map((breakpoint) => {
                    return (
                      <Box key={breakpoint} className="outline-box-child">
                        <TextField
                          name={`${breakpoint}`}
                          value={(value as ResponsiveFieldValue)[breakpoint]}
                          onChange={handleResponsiveChangeInput(id, breakpoint)}
                          fullWidth
                          placeholder={breakpoint}
                          sx={{ ".MuiInputBase-root": { borderRadius: 0 } }}
                          size="small"
                        />
                      </Box>
                    );
                  })
                ) : (
                  <Box className="outline-box-child">
                    <TextField
                      name="value"
                      placeholder="value"
                      value={value}
                      onChange={handleChange(id)}
                      fullWidth
                      sx={{ ".MuiInputBase-root": { borderRadius: 0 } }}
                      size="small"
                    />
                  </Box>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                flex="0 0 auto"
                className="outline-box"
              >
                <Box p="0.35rem 0.5rem" className="outline-box-child">
                  <Checkbox
                    disableRipple
                    size="small"
                    name="responsive"
                    checked={responsive}
                    onClick={handleResponsiveChange(id)}
                    inputProps={{ "aria-label": `${id}-responsive` }}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

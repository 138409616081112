import { MouseEvent, useCallback, useMemo, useRef, useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
  MeasuringStrategy,
  DropAnimation,
  defaultDropAnimation,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSwappingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import {
  addItemDeep,
  findItemDeep,
  flattenFields,
  getItemsWithUniqueIds,
  handleDragEndUpdater,
  removeItemDeep,
  updateItemDeep,
} from "./sortableListUtils";
import { Box, Button, Grid, IconButton, Paper } from "@mui/material";
import { InputComponentProps } from "../FormWrapper/types";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DragIndicator from "@mui/icons-material/DragIndicator";
import FlipMoveComponent from "react-flip-move";
import { SortableItem } from "./SortableItem";
import { FormSchemaForm } from "../FormSchemaForm";
import { Add } from "@mui/icons-material";
import usePrevious from "../../hooks/usePrevious";
import DialogDrawer from "../DialogDrawer/DialogDrawer";

const FlipMove: any = FlipMoveComponent;

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  // @ts-ignore
  dragSourceOpacity: 0.5,
};

export function SortableList({
  field,
  helpers,
  meta,
  config,
}: InputComponentProps<any[]>) {
  const isAnimatingRef = useRef<boolean>(false);
  const [items, setItems] = useState<any[]>(() =>
    getItemsWithUniqueIds(field.value, config)
  );
  const prevItems = usePrevious(items);
  const [activeId, setActiveId] = useState(null);
  const [editingElement, setEditingElement] = useState<{ id: string } | null>(
    null
  );
  const [collapsedGroups, setCollapsedGroups] = useState<
    Record<string, boolean>
  >({});

  const allFlattenedFields = useMemo(
    () => flattenFields(items, config),
    [items]
  );

  const filteredFlattenedFields = useMemo(() => {
    return allFlattenedFields.filter(
      (el) =>
        el.ancestorIds.every((id: string) => id !== activeId) &&
        el.ancestorIds.every((id: string) => !collapsedGroups[id])
    );
  }, [allFlattenedFields, activeId, collapsedGroups]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: any) => {
    const { active } = event;
    setActiveId(active.id);
    setEditingElement(null);
  };

  const createRemoveHandler = (id: string) => () => {
    setItems(removeItemDeep(items, id, config));
  };

  const createEditHandler = (element: any) => () => {
    if (editingElement && editingElement.id === element.id) {
      setEditingElement(null);
    } else {
      setEditingElement(element);
    }
  };

  const createToggleHandler = (groupdId: string) => () => {
    setCollapsedGroups((prev) => ({ ...prev, [groupdId]: !prev[groupdId] }));
  };

  const createAddHandler =
    (parentId: string | null) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const nextItems = addItemDeep(items, config, parentId);
      setItems(nextItems);
      helpers.setValue(nextItems);
    };

  const handleUpdateElement = (updatedElement: any) => {
    const nextValue = updateItemDeep(items, updatedElement, config);
    setItems(nextValue);
    helpers.setValue(items);
  };

  const renderFormElement = (element: any, isClone = false) => {
    const {
      data: { fieldLabel },
    } = config || { data: {} };

    const childItems = element[config.name] ?? [];

    return (
      <Box width="100%" padding={1} borderRadius={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            width: "100%",
            cursor: childItems.length > 0 ? "pointer" : undefined,
          }}
          onClick={
            childItems.length > 0 ? createToggleHandler(element.id) : undefined
          }
        >
          <Box display="flex" gap={1}>
            <span>{element[fieldLabel] || element.id}</span>
            <span>
              <IconButton size="small" onClick={createAddHandler(element.id)}>
                <Add />
              </IconButton>
            </span>
          </Box>

          {childItems.length > 0 && (
            <ExpandMore
              sx={{
                transform:
                  collapsedGroups[element.id] || element.id === activeId
                    ? undefined
                    : "rotate(180deg)",
              }}
            />
          )}
        </Box>
        {!activeId && !isClone && (
          <DialogDrawer
            title={element[fieldLabel] || element.id}
            footer={
              <Button
                onClick={helpers.submitForm}
                type="button"
                variant="contained"
              >
                Save item
              </Button>
            }
            onClose={() => setEditingElement(null)}
            isOpen={editingElement?.id === element.id || element.isNew}
          >
            <Box mt={2}>
              <FormSchemaForm
                form={config as any}
                initialValues={element}
                onChange={handleUpdateElement}
                isFieldsOnly
              />
            </Box>
          </DialogDrawer>
        )}
      </Box>
    );
  };

  const setNewOverId = useCallback(
    (overId: string, insideOutside = "outside") => {
      if (isAnimatingRef.current) {
        return;
      }
      if (overId === activeId) {
        return;
      }

      setItems((prevItems) => {
        const newItems = handleDragEndUpdater(
          prevItems,
          config,
          activeId || "",
          overId,
          undefined,
          insideOutside
        );
        return newItems;
      });
    },
    [activeId]
  );

  const renderClone = (activeId: string) => {
    const activeItem = findItemDeep(activeId, items, config);

    return (
      <Paper elevation={3} style={{ padding: 0 }}>
        <Box width="100%" display="flex" marginBottom={2}>
          <span>
            <IconButton>
              <DragIndicator />
            </IconButton>
          </span>

          {renderFormElement(activeItem, true)}
        </Box>
      </Paper>
    );
  };

  const handleDragEnd = (event: any) => {
    helpers.setValue(items);
    setEditingElement(null);
    setActiveId(null);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Box minWidth="100%">
        <SortableContext
          id="root"
          items={filteredFlattenedFields.map((item) => item.id)}
          strategy={rectSwappingStrategy}
        >
          <Paper elevation={3}>
            <Box p={2}>
              <FlipMove
                typeName={Grid}
                container
                spacing={2}
                data-form-wrapper="true"
                maintainContainerHeight
                onStartAll={() => {
                  isAnimatingRef.current = true;
                }}
                onFinishAll={() => {
                  setTimeout(() => {
                    isAnimatingRef.current = false;
                  }, 250);
                }}
                duration={"250ms"}
              >
                {filteredFlattenedFields.map((element) => {
                  return (
                    <SortableItem
                      isGhost={element.id === activeId}
                      key={element.id}
                      id={element.id}
                      isCollapsed={collapsedGroups[element.id]}
                      onRemove={createRemoveHandler(element.id)}
                      onEdit={createEditHandler(element)}
                      setOverId={setNewOverId}
                      element={element}
                      handleSaveElement={handleUpdateElement}
                    >
                      {renderFormElement(element)}
                    </SortableItem>
                  );
                })}
              </FlipMove>
            </Box>
          </Paper>
          <IconButton onClick={createAddHandler(null)}>
            <Add />
          </IconButton>
        </SortableContext>
      </Box>
      <DragOverlay>{activeId ? renderClone(activeId) : null}</DragOverlay>
    </DndContext>
  );
}

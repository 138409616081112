import { SxProps } from "@mui/material";
import { generateId } from "../../../../utils/generateId";
import { SxItem } from "./SxField";

export const BREAKPOINTS = ["xs", "sm", "md", "lg", "xl"];

export const isObject = (value: any) => typeof value === "object";

export const getType = (value: any) => {
  if (isObject(value)) {
    const [firstVal] = Object.values(value).filter(Boolean);
    return firstVal ? typeof firstVal : "string";
  }

  return value ? typeof value : "string";
};

export const buildSxPropsArray = (
  sxProps: SxProps = {},
  withBlank = true
): SxItem[] => {
  const items = Object.keys(sxProps as any)
    .sort()
    .reduce((items: SxItem[], key, index) => {
      const value = (sxProps as any)[key];
      return [
        ...items,
        {
          id: generateId("sx"),
          property: key,
          value: convertValue(value),
          type: getType(value),
          responsive: isObject(value),
        },
      ];
    }, []);
  return items;
};

const isEmptyObject = (obj: any = {}) => Object.keys(obj).length === 0;

const convertResponsiveValues = (values: any): any => {
  const responsiveValues = BREAKPOINTS.reduce((nextValues: any, key) => {
    const val = values[key];
    if (val !== undefined) {
      return { ...nextValues, [key]: convertValue(val) };
    }
    return nextValues;
  }, {});
  if (isEmptyObject(responsiveValues)) {
    return undefined;
  }
  return responsiveValues;
};

const convertStringValue = (str: string) => {
  if (str.length === 0) {
    return undefined;
  }
  try {
    const num = Number(str);
    return !isNaN(num) ? num : str;
  } catch (err) {
    return str;
  }
};

export const convertValue = (value: any) => {
  if (isObject(value)) {
    return convertResponsiveValues(value);
  }

  if (!value) {
    return undefined;
  }

  return convertStringValue(value.toString());
};

export const buildSxPropsFromArray = (items: SxItem[]) => {
  return items.reduce((sxProps: SxProps, item) => {
    const { property, value } = item;
    const newValue = convertValue(value);

    if (property && newValue) {
      return {
        ...sxProps,
        [property]: newValue,
      };
    }
    return sxProps;
  }, {});
};

export const getValueFromResponsiveField = (
  value: any
): string | number | undefined => {
  return BREAKPOINTS.reduce(
    (newValue: string | number | undefined, breakpoint: string) => {
      if (!newValue && value[breakpoint]) {
        newValue = value[breakpoint];
      }
      return newValue;
    },
    undefined as string | number | undefined
  );
};

import React, { createContext, useContext, PropsWithChildren } from "react";
import { FormDto } from "../../../dtos";

type Props = {
  formSchema: FormDto;
};

const SchemaContext = createContext<FormDto | undefined>(undefined);

export const FormProvider: React.FC<PropsWithChildren<Props>> = ({
  formSchema,
  children,
}) => {
  return (
    <SchemaContext.Provider value={formSchema}>
      {children}
    </SchemaContext.Provider>
  );
};

export const useFormDto = () => {
  const context = useContext(SchemaContext);

  if (!context) {
    throw new Error(
      "FormProvider must be used within a FormProvider component"
    );
  }

  return context;
};

import { FormFieldDto } from "../../dtos";
import {
  findElementDeep,
  removeElementDeep,
  updateElementDeep,
} from "../../utils/recursiveUtils";
import { generateId } from "../../utils/generateId";
import { FieldConfig } from "formik";

export const createNewElement = (type: string): FormFieldDto => {
  return {
    id: generateId("field"),
    type,
    label: "",
    name: "",
    isNew: true,
    fields: type === "array" ? [createNewElement("text")] : [],
    ts: 0,
    options: [],
    conditions: [],
  };
};

export const getIsChildrenAllowed = (type: string) => {
  return ["array", "object", "fieldgroup"].includes(type?.toLowerCase());
};

export const formElementTypes = [
  { id: "TextField", type: "TextField", label: "Text Input" },
  { id: "TextareaAutosize", type: "TextareaAutosize", label: "Text Area" },
  { id: "Select", type: "Select", label: "Dropdown" },
  {
    id: "FieldGroup",
    type: "FieldGroup",
    label: "Field Group",
  },
];

export const isActiveElementNew = (activeId: string) => {
  if (!activeId) {
    return false;
  }
  return !!formElementTypes.find((el) => el.id === activeId);
};

export function flattenFields(
  items: any[],
  config: FormFieldDto,
  parentId: string | null = null,
  depth = 0,
  ancestorIds: string[] = []
): any[] {
  if (!items?.length) {
    return [];
  }

  return items.reduce<any[]>((acc, item, index) => {
    return [
      ...acc,
      { ...item, parentId, depth, index, ancestorIds },
      ...flattenFields(item[config.name] || [], config, item.id, depth + 1, [
        ...ancestorIds,
        item.id,
      ]),
    ];
  }, []);
}

export const findItemDeep = (id: string, items: any[], config: FieldConfig) => {
  return findElementDeep(items, id, config.name);
};

export const getItemsWithUniqueIds = (
  items: any[],
  config: FormFieldDto,
  seen: Record<string, boolean> = {}
): any[] => {
  if (!items?.length) {
    return [];
  }
  return items.map((item) => {
    const childItems = item[config.name] || [];
    const newId =
      item.id && !seen[item.id]
        ? item.id
        : generateId(config.name.toLowerCase());
    seen[newId] = true;

    if (childItems.length) {
      return {
        ...item,
        id: newId,
        fields: getItemsWithUniqueIds(childItems, config, seen),
      };
    }
    return { ...item, id: newId };
  });
};

export const removeItemDeep = (
  items: any[],
  id: string,
  config: FormFieldDto
) => {
  return removeElementDeep(items, id, config.name);
};

export const updateItemDeep = (
  items: any[],
  updatedField: any,
  config: FormFieldDto
) => {
  const newFields = updateElementDeep(updatedField, items, config.name);
  return newFields;
};

export type DomRect = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  offsetRight?: number;
  offsetLeft?: number;
};

export const getElementRect = (el: HTMLDivElement) => {
  if (el) {
    const rect = el.getBoundingClientRect();
    if (rect) {
      return {
        width: rect.width,
        height: rect.height,
        top: rect.top,
        left: rect.left,
        right: rect.right,
        bottom: rect.bottom,
        offsetLeft: el.offsetLeft,
        offsetRight: el.offsetLeft + el.offsetWidth,
      };
    }
  }
  return {};
};

export const handleDragEndUpdater = (
  items: any[] = [],
  config: FormFieldDto,
  draggingId: string,
  overId: string,
  newElement: FormFieldDto | undefined,
  insideOutside = "outside"
) => {
  const activeId = newElement?.id ?? draggingId;

  if (activeId === overId) {
    return items;
  }

  if (!overId && items.length === 0) {
    return !!newElement ? [newElement] : [createNewElement("TextField")];
  }

  const activeElement = !!newElement
    ? newElement
    : findItemDeep(activeId, items, config);
  const overElement = findItemDeep(overId, items, config);

  if (!activeElement || !overElement) {
    return items;
  }

  let [overParentId] = overElement.ancestorIds.reverse();
  if (insideOutside === "inside") {
    overParentId = overElement.id;
  }
  // const [activeParent] = activeElement.ancestorIds.reverse();

  if (!overParentId) {
    const newFields = removeItemDeep(items, activeId, config);
    newFields.splice(overElement.index, 0, activeElement);
    return newFields;
  }

  let newItems = structuredClone(items);
  newItems = removeItemDeep(newItems, activeId, config);
  const overParent = findItemDeep(overParentId as string, newItems, config);

  overParent[config.name].splice(overElement.index, 0, activeElement);

  const withActiveElement = updateItemDeep(newItems, overParent, config);

  return withActiveElement;
};

const createNewItem = (field: FormFieldDto): FormFieldDto => {
  const baseItem: FormFieldDto = {
    id: generateId(field.name),
    isNew: true,
    ts: 0,
    name: field.name,
    type: field.type,
    options: [],
    label: "",
    fields: [],
    conditions: [],
  };
  return (field.fields || []).reduce((item, childField) => {
    const { name, type } = childField;
    if (type === "nubmer") {
      return { ...item, [name]: 0 };
    }
    if (type === "text") {
      return { ...item, [name]: "" };
    }
    if (type === "mixed" || type === "object") {
      if (field.isArray) {
        return { ...item, [name]: [] };
      }
      return { ...item, [name]: createNewItem(childField) };
    }
    return item;
  }, baseItem);
};

export const addItemDeep = (
  items: any[],
  config: FormFieldDto,
  parentId: any
) => {
  const newItem = createNewItem(config);
  if (parentId) {
    const parentEl = findItemDeep(parentId, items, parentId);
    if (!parentEl) {
      return items;
    }
    const parentChildren = parentEl[config.name] || [];
    return updateItemDeep(
      items,
      {
        ...parentEl,
        [config.name]: [...parentChildren, newItem],
      },
      config
    );
  }

  return [...items, newItem];
};
